import qs from 'query-string'
import api from './api'

export const conferenceApi = api.injectEndpoints({
  endpoints: builder => ({
    getConferences: builder.query({
      query: ({ domainId, eventId, userId, all, userId2 }) =>
        `/v2/domains/${domainId}/events/${eventId}/conferences?${qs.stringify({ userId, all, userId2 })}`,
      transformResponse: response => response.data,
      providesTags: ['CONFERENCE_SLOT_USER', 'EVENT_USER'],
    }),
    getConferenceSlots: builder.query({
      query: ({ domainId, eventId, conferenceId, userId }) =>
        `/v2/domains/${domainId}/events/${eventId}/conferences/${conferenceId}/slots?${qs.stringify({ userId })}`,
      transformResponse: response => response.data,
      providesTags: ['CONFERENCE_SLOT_USER', 'EVENT_USER'],
    }),
    createConferenceSlotUser: builder.mutation({
      query: ({ domainId, eventId, conferenceId, slotId, userId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/conferences/${conferenceId}/slots/${slotId}/users/${userId}`,
        method: 'POST',
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['CONFERENCE_SLOT_USER'],
    }),
    removeConferenceSlotUser: builder.mutation({
      query: ({ domainId, eventId, conferenceId, userId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/conferences/${conferenceId}/slots/users/${userId}`,
        method: 'DELETE',
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['CONFERENCE_SLOT_USER'],
    }),
  }),
})

export const {
  useGetConferencesQuery,
  useLazyGetConferencesQuery,
  useGetConferenceSlotsQuery,
  useCreateConferenceSlotUserMutation,
  useRemoveConferenceSlotUserMutation,
} = conferenceApi
