import apiV2 from '.'

export const sectionApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    getSections: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/events/${eventId}/sections`,
      transformResponse: response => response.data?.sections || [],
      providesTags: ['SECTION'],
    }),
  }),
})

export const { useGetSectionsQuery } = sectionApi
