import dayjs from 'dayjs'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getDate = date => {
  return capitalizeFirstLetter(dayjs(date).format('dddd DD MMMM'))
}

export const getTime = date => {
  return dayjs(date).format('HH:mm')
}

export const getDateTime = date => {
  return `${getDate(date)} à ${getTime(date)}`
}

export const getDateTime2 = (dateA, dateB) => {
  return `${getDate(dateA)} de ${getTime(dateA)} à ${getTime(dateB)}`
}
