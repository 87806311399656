import { Button, Form } from 'antd'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetConferencesQuery } from '../../api/conference.api'
import { useGetAssociateParticipationQuery } from '../../api/v2/conferences'
import { useMyContext } from '../../context/context'
import useHistoryCustom from '../../hooks/useHistoryCustom'
import { useSteps } from '../steps/Steps'
import Conference from './Conference'

const Conferences = ({ userId, nextStep }) => {
  const history = useHistoryCustom()
  const { domainId, eventId, settings, userId: myUserId, myContext } = useMyContext()
  const params = useParams()

  const { NextButton, CompanionName } = useSteps()
  const [form] = Form.useForm()

  const currentUserId = userId || params?.userId
  const isMe = Number(currentUserId) === Number(myUserId)

  const { data: conferences } = useGetConferencesQuery({ domainId, eventId, userId2: currentUserId })
  const { data: userConferences } = useGetConferencesQuery({ domainId, eventId, userId: currentUserId })
  const { data, isFetching } = useGetAssociateParticipationQuery({
    ...myContext,
    oneAssociate: settings?.FO_MEETING_CONFERENCES_EMPLOYEES_ONE_ONLY,
  })

  const quotaReached = useMemo(
    () =>
      userConferences && Number(settings?.FO_MEETING_CONFERENCES_MAX)
        ? userConferences.length >= Number(settings.FO_MEETING_CONFERENCES_MAX)
        : false,
    [settings, userConferences]
  )

  const onSubmit = () => {
    if (nextStep) {
      nextStep()
    } else {
      history.push('')
    }
  }

  if (isFetching) {
    return null
  }

  return (
    <div style={{ padding: 10 }}>
      <Form onFinish={onSubmit} form={form}>
        {data?.hideConferences ? (
          <>
            {settings?.FO_MEETING_CONFERENCES_EMPLOYEES_ONE_ONLY_HTML ? (
              <div dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFERENCES_EMPLOYEES_ONE_ONLY_HTML.html }} />
            ) : (
              <div>Configurer le message HTML</div>
            )}
          </>
        ) : (
          <>
            {settings?.FO_MEETING_CONFERENCES_TOP_HTML && (
              <div style={{ marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_CONFERENCES_TOP_HTML.html }} />
            )}
            {CompanionName}

            {conferences
              ?.filter(f => !(f.isDisabledForCompanions && !isMe))
              .map(conference => (
                <Conference
                  key={`conference-${conference.id}-${userConferences?.length}`}
                  quotaReached={quotaReached}
                  conference={conference}
                  userId={currentUserId}
                  disabled={userConferences?.length > 0 && settings?.CONFERENCE_UNIQUE}
                  required={userConferences?.length < 1 && settings?.CONFERENCE_MIN_1}
                  form={form}
                />
              ))}
          </>
        )}

        {NextButton ? (
          <NextButton htmlType="submit" />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: 10 }}>
            <Button type="primary" htmlType="submit">
              TERMINER
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}

export default Conferences
