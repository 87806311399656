import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDownloadBadgeMutation } from '../../api/api'
import { useMyContext } from '../../context/context'
import useLog from '../../hooks/useViewLog'
import Button from '../../shared/button'

const Badge = () => {
  const myContext = useMyContext() as any
  const params = useParams<{ badgeId: string }>()
  const [loading, setLoading] = useState<boolean>(true)

  const [createLog] = useLog()

  const [downloadBadgeV2] = useDownloadBadgeMutation()

  const { user } = myContext

  useEffect(() => {
    if (Number(params.badgeId)) {
      downloadBadgeV2({ ...myContext, userId: user?.id, badgeId: Number(params.badgeId) })
        .unwrap()
        .then((url: any) => window.open(url, '_blank'))
        .then(() => createLog('DOWNLOAD_BADGE'))
        .then(() => setLoading(false))
    }
    // downloadBadge(user?.id, Number(params.badgeId))
    //   .then(() => createLog('DOWNLOAD_BADGE'))
    //   .then(() => setLoading(false))
  }, [createLog, downloadBadgeV2, myContext, params.badgeId, user])

  return (
    <div style={{ textAlign: 'center' }}>
      <p>{loading ? 'Votre badge est en cours de téléchargement, veuillez patienter...' : 'Téléchargement terminé'}</p>
      <p>Si votre téléchargement ne fonctionne, veuillez essayer le bouton ci-dessous</p>
      <Button
        style={{ marginTop: '0.5em' }}
        onClick={() => {
          downloadBadgeV2({ ...myContext, userId: user?.id, badgeId: Number(params.badgeId) })
            .unwrap()
            .then((url: any) => window.open(url, '_blank'))
          // downloadBadge(user?.id, Number(params.badgeId)).then(() => setLoading(false))
        }}
        label="Je veux relancer le téléchargement"
      />
    </div>
  )
}

export default Badge
