import React, { useCallback, useEffect, useRef, useState } from 'react'
import { gaugeChart } from 'gauge-chart'

function Gauge({ responses, totalVotes }) {
  const ref = useRef()
  const parentRef = useRef()
  const [width, setWidth] = useState(null)

  const displayGauge = useCallback(() => {
    if (!responses) return
    console.log(responses)

    const sumResults = responses.reduce((prev, curr, i) => {
      const p = Math.floor((100 / (responses.length - 1)) * i)
      return p * curr.votes + prev
    }, 0)
    const avg = sumResults / totalVotes

    const gaugeOptions = {
      hasNeedle: true,
      needleColor: 'black',
      needleUpdateSpeed: 4000,
      arcDelimiters: responses
        .map((r, i) => Math.floor((100 / (responses.length - 1)) * i))
        .filter(f => f !== 0 && f !== 100),
      arcColors: responses.map(r => r.color),
    }

    if (ref.current) {
      ref.current.innerHTML = ''
      gaugeChart(ref.current, width, gaugeOptions).updateNeedle(avg)
    }
  }, [responses, totalVotes, width])

  useEffect(() => {
    displayGauge()
  }, [displayGauge])

  useEffect(() => {
    if (parentRef.current) {
      setWidth(parentRef.current.offsetWidth)
      window.onresize = () => {
        setWidth(parentRef.current.offsetWidth)
      }
    }
  }, [])

  return (
    <div ref={parentRef}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 100,
          width,
        }}
        ref={ref}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: '-15% 10% 0' }}>
        {responses.map(r => (
          <div style={{ width: `${Math.floor(100 / (responses.length * 2))}%` }}>
            <img src={r.image} style={{ width: `100%` }} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Gauge
