import { Radio } from 'antd'
import React, { useEffect, useState } from 'react'

export default function FormRadio({ label, options, onChange, value, style }) {
  const [v, setV] = useState(null)

  useEffect(() => {
    if (value) {
      setV(value)
    }
  }, [value])

  return (
    <div style={{ display: 'flex', gap: 16 }}>
      <div style={{ fontWeight: 'bold' }}>{label}</div>
      <Radio.Group
        style={style}
        value={v ? Object.keys(v)[0] : null}
        onChange={e => {
          const vv = e.target.value ? { [e.target.value]: true } : null
          setV(vv)
          onChange(vv)
        }}
        options={options}
      />
    </div>
  )
}
