import React, { CSSProperties, useState } from 'react'
import { useMyContext } from '../context/context'

interface IProps {
  onClick: () => void
  label: string
  labelSuccess?: string
  style?: CSSProperties
  disabled?: boolean
}

const Button = ({ onClick, label, labelSuccess, style, disabled }: IProps) => {
  const { settings } = useMyContext() as any

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  return (
    <button
      style={{ backgroundColor: settings?.bgPrimaryColor, ...style }}
      type="button"
      className={`button is-primary ${loading && 'is-loading'}`}
      onClick={async () => {
        setLoading(true)
        await onClick()
        setSuccess(true)
        setLoading(false)
      }}
      disabled={success || disabled}
    >
      {success && labelSuccess ? labelSuccess : label}
    </button>
  )
}

export default Button
