import React, { useCallback, useEffect, useState } from 'react'
import { Typography } from 'antd'
import { TrophyFilled } from '@ant-design/icons'
import LeaderboardApi from '../../services/api/Leaderboard'
import useWebsocket from '../../hooks/useWebsocket'
import PresentationFullScreen from '../../shared/PresentationFullScreen'

const { Text } = Typography

function Leaderboard() {
  const [ws] = useWebsocket(true)

  const [data, setData] = useState([])

  const fetchData = useCallback(
    () =>
      ws &&
      new LeaderboardApi()
        .findAll(ws.eventLive.leaderboardLimit, !!ws.eventLive.showTeams)
        .then(setData)
        .catch(() => setData([])),
    [ws]
  )

  useEffect(() => {
    fetchData()

    const loop = setTimeout(() => {
      fetchData()
    }, 5000)

    return () => {
      clearTimeout(loop)
    }
  }, [fetchData])

  const getColors = index => {
    if (index === 0) return '#FFD700'
    if (index === 1) return '#C0C0C0'
    if (index === 2) return '#cd7f32'
    return null
  }

  return (
    <PresentationFullScreen>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: 60 }}>Classement</Text>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {data.map((item, index) => (
            <div style={{ display: 'flex', alignItems: 'center', margin: 30 }}>
              {[0, 1, 2].includes(index) && (
                <TrophyFilled
                  style={{
                    fontSize: 70,
                    color: getColors(index),
                    marginRight: 15,
                  }}
                />
              )}

              <div>
                <div>
                  <Text style={{ fontSize: 30 }} strong>{`${item.pseudo || item.label || `${item.firstName} ${item.lastName}`}`}</Text>
                </div>
                <Text style={{ fontSize: 20 }} type="secondary">{`${item.points} point(s)`}</Text>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PresentationFullScreen>
  )
}

export default Leaderboard
