import 'antd/dist/antd.css'
import dayjs from 'dayjs'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './app'
import { store } from './redux'
import './services/dateFormat'
import './style/index.scss'

require('dayjs/locale/fr')

dayjs.locale('fr')

const Index = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(<Index />, document.getElementById('root'))
