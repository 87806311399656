import { useCallback, useEffect } from 'react'
import { useCreateLogMutation } from '../api/log.api'
import { useMyContext } from '../context/context'

const useLog = action => {
  const { eventId, domainId } = useMyContext()

  const [createLog] = useCreateLogMutation()

  const create = useCallback(
    _action => {
      if (eventId && domainId && _action) {
        createLog({ eventId, domainId, action: _action })
      }
    },
    [createLog, domainId, eventId]
  )

  useEffect(() => {
    if (action) {
      create(action)
    }
  }, [action, create])

  return [create]
}

export default useLog
