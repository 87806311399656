import React, { CSSProperties } from 'react'
import nextId from 'react-id-generator'

interface IProps {
  label?: string
  options: {
    label: string
    value: string
  }[]
  required?: boolean
  isHorizontal?: boolean
  inputIsHorizontal?: boolean
  selectedValues: string[]
  type: 'radio' | 'checkbox'
  style?: CSSProperties
  disabled?: boolean
  setSelectedValue(value: string): void
}

const InputRadioCheckbox = ({
  options,
  label,
  required,
  setSelectedValue,
  isHorizontal,
  selectedValues,
  type,
  inputIsHorizontal,
  style,
  disabled,
}: IProps) =>
  isHorizontal ? (
    <div className="field is-horizontal" style={style}>
      <div className="field-label is-small">
        {label && (
          <label className="label" htmlFor={label} style={{ margin: 0 }}>
            {required ? (
              <>
                {label}
                <span className="has-text-danger"> *</span>
              </>
            ) : (
              label
            )}
          </label>
        )}
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            {options.map(({ label: optionLabel, value }) => {
              const id = nextId()
              const Child = () => (
                <>
                  <input
                    id={id}
                    className="is-checkradio is-small"
                    type={type}
                    name={label}
                    onChange={() => setSelectedValue(value)}
                    required={required}
                    checked={!!selectedValues.find(v => v === value)}
                    disabled={disabled}
                  />
                  <label style={{ margin: 0 }} htmlFor={id}>
                    {optionLabel}
                  </label>
                </>
              )
              return inputIsHorizontal ? (
                <React.Fragment key={id}>
                  <Child />
                </React.Fragment>
              ) : (
                <div key={id}>
                  <Child />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="field">
      {label && (
        <label className="label is-small" htmlFor={label}>
          {required ? (
            <>
              {label}
              <span className="has-text-danger"> *</span>
            </>
          ) : (
            label
          )}
        </label>
      )}
      <div className="control">
        {options.map(({ label: optionLabel, value }) => {
          const id = nextId()
          const Child = () => (
            <>
              <input
                id={id}
                className="is-checkradio is-small"
                type={type}
                name={label}
                onChange={() => setSelectedValue(value)}
                required={required}
                checked={!!selectedValues.find(v => v === value)}
                disabled={disabled}
              />
              <label style={{ margin: 0 }} htmlFor={id}>
                {optionLabel}
              </label>
            </>
          )
          return inputIsHorizontal ? (
            <React.Fragment key={id}>
              <Child />
            </React.Fragment>
          ) : (
            <div key={id}>
              <Child />
            </div>
          )
        })}
      </div>
    </div>
  )

export default InputRadioCheckbox
