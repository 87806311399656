import qs from 'query-string'
import api from './api'

export const logApi = api.injectEndpoints({
  endpoints: builder => ({
    createLog: builder.mutation({
      query: ({ domainId, eventId, action }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/logs`,
        method: 'POST',
        body: { action },
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['Log'],
    }),
    getLogsTotal: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/logs/total`,
      transformResponse: response => response.data,
      providesTags: ['Log'],
    }),
    getLogs: builder.query({
      query: ({ domainId, eventId, pageSize, pageCurrent, createExport }) =>
        `/v2/domains/${domainId}/events/${eventId}/logs?${qs.stringify({ pageSize, pageCurrent, createExport })}`,
      transformResponse: response => response.data,
      providesTags: ['Log'],
    }),
  }),
})

export const { useCreateLogMutation, useGetLogsQuery, useGetLogsTotalQuery, useLazyGetLogsQuery } = logApi
