import { Form } from 'antd'
import React, { useEffect } from 'react'
import { useGetModelsQuery, useGetUserModelsQuery, useUpdateUserModelsMutation } from '../../api/v2/models'
import FormCheckbox from '../../components/form/Checkbox'
import FormRadio from '../../components/form/Radio'
import FormSelect from '../../components/form/Select'
import { useMyContext } from '../../context/context'
import { useSteps } from './Steps'

export default function Quotas() {
  const { myContext, settings } = useMyContext()
  const { nextStep, NextButton, userId } = useSteps()
  const [form] = Form.useForm()

  const { data: models } = useGetModelsQuery(myContext)
  const { data } = useGetUserModelsQuery({ ...myContext, userId })
  const [updateExt, { isLoading }] = useUpdateUserModelsMutation()

  useEffect(() => {
    if (data && form) {
      form.setFieldsValue(data)
    }
  }, [data, form])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {settings?.FO_MEETING_QUOTAS_HTML && (
        <div style={{ padding: 16 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_QUOTAS_HTML.html }} />
      )}
      <div style={{ height: 16 }} />
      <Form
        form={form}
        onFinish={async values => {
          await updateExt({ ...myContext, userId, ...values }).unwrap()
          nextStep()
        }}
      >
        {models?.map(model => {
          const name = model.model === 'U' ? 'userExt' : 'participationExt'

          if (model.filter === 'select') {
            return (
              <Form.Item
                label={model.value}
                name={[name, model.key]}
                rules={[{ required: settings?.FO_MEETING_QUOTAS_REQUIRED, message: 'Ce choix est obligatoire' }]}
              >
                <FormSelect options={model.values.map(m => ({ label: m.value, value: m.key, disabled: m.isDisabled }))} />
              </Form.Item>
            )
          }

          if (model.filter === 'radio') {
            return (
              <Form.Item
                name={[name, model.key]}
                rules={[{ required: settings?.FO_MEETING_QUOTAS_REQUIRED, message: 'Ce choix est obligatoire' }]}
              >
                <FormRadio
                  label={model.value}
                  style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
                  options={model.values.map(m => ({ label: m.value, value: m.key, disabled: m.isDisabled }))}
                />
              </Form.Item>
            )
          }

          if (model.filter === 'checkbox') {
            return (
              <Form.Item
                name={[name, model.key]}
                rules={[{ required: settings?.FO_MEETING_QUOTAS_REQUIRED, message: 'Ce choix est obligatoire' }]}
              >
                <FormCheckbox
                  label={model.value}
                  style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
                  options={model.values.map(m => ({ label: m.value, value: m.key, disabled: m.isDisabled }))}
                />
              </Form.Item>
            )
          }

          return null
        })}
      </Form>

      <NextButton
        loading={isLoading}
        onClick={async () => {
          form.submit()
        }}
      />
    </div>
  )
}
