import React, { useEffect, useState } from 'react'
import { useGetEventUserQuery } from '../../api/eventUser.api'
import { useMyContext } from '../../context/context'
import InputRadioCheckbox from '../../shared/form/input.radio.checkbox'
import { getDate } from '../../utils/date'
import { useGetArticlesQuery } from '../../api/article.api'
import { useSteps } from './Steps'

const Dates = () => {
  const [daysId, setDaysId] = useState([])

  const { event, myContext, settings } = useMyContext()
  const { nextStep, userId, NextButton, CompanionName, setDaysId: setDaysId2 } = useSteps()

  const { data: eventUser } = useGetEventUserQuery({ ...myContext, userId })
  const { data: articles } = useGetArticlesQuery(myContext)

  useEffect(() => {
    if (Array.isArray(event.days)) {
      if (event.days.length <= 1) {
        setDaysId2(event.days.map(m => m.id))
        nextStep()
      }
    }
  }, [daysId, event, nextStep, setDaysId2])

  useEffect(() => {
    if (Array.isArray(event.days) && event.days.length > 1) {
      if (eventUser?.UserEvent) {
        setDaysId(eventUser?.UserEvent?.UserEventDays?.filter(ued => ued.registered)?.map(ued => ued.eventDayId))
      }
    }
  }, [event, eventUser])

  if (event.days.length <= 1) {
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {settings?.FO_MEETING_DATES_HTML && (
        <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_DATES_HTML?.html }} />
      )}

      {CompanionName}
      <InputRadioCheckbox
        label={
          <div
            dangerouslySetInnerHTML={{
              __html: settings?.FO_MEETING_DATES_TITLE?.replace(/\n/g, '<br/>') || "Je souhaite m'inscrire pour \n les dates suivantes :",
            }}
          />
        }
        style={{ textAlign: 'center', marginBottom: 10 }}
        options={
          event?.days.map(ed => {
            const p = articles?.reduce((prev, curr) => {
              const isSelected = curr.days?.[ed.id]?.isSelected
              if (isSelected) {
                return prev + curr.price
              }
              return prev
            }, 0)
            return {
              label: `${getDate(ed.day)} ${p ? `(${p} € H.T.)` : ''}`,
              value: ed.id.toString(),
            }
          }) || []
        }
        selectedValues={daysId.map(d => d.toString())}
        type="checkbox"
        setSelectedValue={v => {
          const dayId = Number(v)
          const find = daysId.find(d => d === dayId)
          if (find) setDaysId(daysId.filter(d => d !== dayId))
          else setDaysId([...daysId, dayId])
        }}
      />
      <NextButton
        onClick={async () => {
          setDaysId2(daysId)
          nextStep()
        }}
        disabled={daysId.length < 1}
      />
    </div>
  )
}

export default Dates
