import qs from 'query-string'
import api from './api'

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    updateUser: builder.mutation({
      query: ({ domainId, userId, user }) => ({
        url: `/domains/${domainId}/users/${userId}`,
        method: 'PUT',
        body: user,
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['User', 'EVENT_USER'],
    }),
    createUser: builder.mutation({
      query: ({ domainId, user, force, eventId }) => ({
        url: `/domains/${domainId}/users?${qs.stringify({ force, eventId })}`,
        method: 'POST',
        body: user,
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['User', 'EVENT_USER'],
    }),
    getMe: builder.query({
      query: ({ domainId }) => `/domains/${domainId}/users/me`,
      transformResponse: response => response.data,
      providesTags: ['User'],
    }),
    getUsers: builder.query({
      query: ({
        domainId,
        eventId,
        pageSize,
        pageCurrent,
        isInvited,
        isRegistered,
        isPresent,
        isRemote,
        createExport,
        formId,
        slotId,
        typo,
        typoId,
      }) =>
        `/v2/domains/${domainId}/events/${eventId}/users?${qs.stringify({
          pageSize,
          pageCurrent,
          isInvited,
          isRegistered,
          isPresent,
          isRemote,
          createExport,
          formId,
          slotId,
          typo,
          typoId,
        })}`,
      transformResponse: response => response.data,
      providesTags: ['User', 'EVENT_USER'],
    }),
  }),
})

export const { useUpdateUserMutation, useGetMeQuery, useGetUsersQuery, useCreateUserMutation, useLazyGetUsersQuery } = userApi
