import qs from 'query-string'
import api from './api'

export const userLinkApi = api.injectEndpoints({
  endpoints: builder => ({
    createUserLink: builder.mutation({
      query: ({ domainId, eventId, userIdA, userIdB, body }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userIdA}/links/${userIdB}`,
        method: 'POST',
        body,
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['UserLinks'],
    }),
    getMyContacts: builder.query({
      query: ({ domainId, eventId, createExport, formId }) =>
        `/v2/domains/${domainId}/events/${eventId}/contacts/me?${qs.stringify({ createExport, formId })}`,
      transformResponse: response => response.data,
      providesTags: ['UserLinks'],
    }),
  }),
})

export const { useCreateUserLinkMutation, useGetMyContactsQuery, useLazyGetMyContactsQuery } = userLinkApi
