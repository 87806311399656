import api from './api'

export const emailApi = api.injectEndpoints({
  endpoints: builder => ({
    sendEmail: builder.mutation({
      query: ({ domainId, eventId, templateId, accountId, usersId, variables }) => ({
        url: `/domains/${domainId}/events/${eventId}/emails/send`,
        method: 'POST',
        body: { templateId, accountId, usersId, variables },
      }),
      transformResponse: response => response.data,
    }),
  }),
})

export const { useSendEmailMutation } = emailApi
