import api from './api'

export const liveApi = api.injectEndpoints({
  endpoints: builder => ({
    updateLive: builder.mutation({
      query: ({ domainId, eventId, live }) => ({
        url: `/domains/${domainId}/events/${eventId}/lives`,
        method: 'PUT',
        body: live,
      }),
      transformResponse: response => response.data,
    }),
    updateMessage: builder.mutation({
      query: ({ domainId, eventId, messageId, message }) => ({
        url: `/domains/${domainId}/events/${eventId}/messages/${messageId}`,
        method: 'PATCH',
        body: message,
      }),
      transformResponse: response => response.data,
    }),
  }),
})

export const { useUpdateLiveMutation, useUpdateMessageMutation } = liveApi
