import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'

function Pie({ responses }) {
  return (
    <div>
      <PieChart
        data={responses.map(r => ({
          title: r.label,
          value: r.votes,
          color: r.color,
        }))}
        style={{ fontSize: '8px', maxHeight: 400 }}
        labelStyle={{ fill: '#fff', opacity: 0.8 }}
        label={({ dataEntry }) => (dataEntry.percentage !== 0 ? `${Math.round(dataEntry.percentage)}%` : '')}
        lineWidth={50}
        labelPosition={100 - 50 / 2}
        animationDuration={4000}
        animate
      />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        {responses.map(r => (
          <div
            style={{
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              marginRight: 20,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                backgroundColor: r.color,
                width: 20,
                height: 20,
                marginRight: 5,
              }}
            />
            {r.image && <img style={{ width: 30, height: 30, marginRight: 10 }} src={r.image} />}
            <span>{r.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Pie
