import React from 'react'
import ReactWordcloud from 'react-wordcloud'
import { useMyContext } from '../../context/context'

function WordCloud({ data }) {
  const { settings } = useMyContext()

  const { wordCloudResponses, wordCloud } = data
  const words = wordCloudResponses.map(d => ({ text: d.response, value: d.count }))

  const options = {
    colors: wordCloud.colors,
    enableTooltip: false,
    deterministic: false,
    fontFamily: wordCloud.fontFamily,
    fontSizes: [30, 80],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: 'log',
    spiral: 'archimedean',
    transitionDuration: 4000,
  }

  return (
    <div style={{ width: '100%', height: '100%', padding: '10%' }}>
      <div style={{ textAlign: 'center', fontSize: 60, color: settings?.textPrimaryColor }}>{wordCloud.content}</div>
      <ReactWordcloud options={options} words={words} maxWords={100} />
    </div>
  )
}

export default WordCloud
