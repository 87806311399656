import { post } from '../fetch'

const route = '/authentications'

const AuthenticationApi = {
  login: (email: string, password: string, eventId: any) => post(`${route}/login`, { email, password, eventId }),
  loginWithCode: (code: string) => post(`/v2/authentications`, { code }),
}

export default AuthenticationApi
