import { TrophyFilled } from '@ant-design/icons'
import { Typography } from 'antd'
import React from 'react'
import useWebsocket from '../../hooks/useWebsocket'
import PresentationFullScreen from '../../shared/PresentationFullScreen'

const { Text } = Typography

function LeaderboardV2() {
  const [data] = useWebsocket(true)

  const getColors = index => {
    if (index === 0) return '#FFD700'
    if (index === 1) return '#C0C0C0'
    if (index === 2) return '#cd7f32'
    return null
  }

  const results = data?.questionResults
    .sort((a, b) => a.votes - b.votes)
    .slice(-3)
    .reverse()
    .map(m => {
      const find = data?.questionResponses.find(f => f.id === m.id)
      return { ...find, ...m }
    })

  return (
    <PresentationFullScreen>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: 60 }}>Classement</Text>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {results?.map((item, index) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: 30,
                animation: `slideFromLeft 1s forwards`,
                animationDelay: `${3 * 3 - 3 * index}s`,
                opacity: 0,
              }}
            >
              {[0, 1, 2].includes(index) && (
                <TrophyFilled
                  style={{
                    fontSize: 70,
                    color: getColors(index),
                    marginRight: 15,
                  }}
                />
              )}

              <div>
                <div>
                  <Text style={{ fontSize: 30 }} strong>
                    {item.label}
                  </Text>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </PresentationFullScreen>
  )
}

export default LeaderboardV2
