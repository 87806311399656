import api from './api'

export const conferenceApi = api.injectEndpoints({
  endpoints: builder => ({
    getEventUser: builder.query({
      query: ({ domainId, eventId, userId }) => `/domains/${domainId}/events/${eventId}/users/${userId}`,
      transformResponse: response => response.data,
      providesTags: ['EVENT_USER', 'User'],
    }),
    getUserByBadge: builder.query({
      query: ({ domainId, eventId, code }) => `/v2/domains/${domainId}/events/${eventId}/users/badges/${code}`,
      transformResponse: response => response.data,
      providesTags: ['User', 'EVENT_USER'],
    }),
    updateEventUser: builder.mutation({
      query: ({ domainId, eventId, userId, eventUser }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}`,
        method: 'PUT',
        body: eventUser,
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['EVENT_USER', 'User'],
    }),
    createInvite: builder.mutation({
      query: ({ domainId, eventId, user }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/users/create-invite`,
        method: 'POST',
        body: { user },
      }),
      transformResponse: response => response.data,
    }),
    updateEventDayUser: builder.mutation({
      query: ({ domainId, eventId, dayId, userId, day }) => ({
        url: `/domains/${domainId}/events/${eventId}/days/${dayId}/users/${userId}`,
        method: 'PUT',
        body: day,
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['EVENT_USER'],
    }),
  }),
})

export const {
  useGetEventUserQuery,
  useUpdateEventUserMutation,
  useUpdateEventDayUserMutation,
  useGetUserByBadgeQuery,
  useCreateInviteMutation,
  useLazyGetEventUserQuery,
} = conferenceApi
