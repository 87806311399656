import React from 'react'
import ShakaPlayer from 'shaka-player-react'
import 'shaka-player/dist/controls.css'

const local = true

function PlayerLocal() {
  const ref = React.useRef(null)

  React.useEffect(() => {
    window.getShakaInst = () => ref.current
  }, [])

  return (
    <div className="live-flex" style={{ width: '50%', flexGrow: 1, padding: '0 15px', backgroundColor: 'black' }}>
      <ShakaPlayer
        ref={ref}
        autoPlay
        src={
          local
            ? 'https://stream-local.frek.io/RESTREAM/thelem_abr/playlist.m3u8'
            : 'https://diff.frek.io/RESTREAM/THELEM_source/playlist.m3u8'
        }
      />
    </div>
  )
}

export default PlayerLocal
