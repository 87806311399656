import React from 'react'
import { useMyContext } from '../../context/context'
import Global from './Global'
import Header from './Header'
import LogsLast from './LogsLast'
import Users from './Users'

function StatsScreen() {
  const myContext = useMyContext()

  const { settings } = myContext

  return (
    <div style={{ padding: '0 10px' }}>
      <div style={{ marginBottom: 10 }}>
        <Header />
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ flex: '1 1 400px', marginBottom: 10 }}>
          <Global />
        </div>
      </div>
      {!settings?.FO_MEETING_STATS_LOGS_HIDE && <LogsLast />}
      <Users />
    </div>
  )
}

export default StatsScreen
