import React from 'react'

interface IMessageError {
  message: string
}

const MessageError = ({ message }: IMessageError) => {
  if (message) {
    return (
      <article className="message is-danger field">
        <div className="message-body">{message}</div>
      </article>
    )
  }
  return null
}

export default MessageError
