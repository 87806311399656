import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useMyContext } from '../context/context'

const GoogleAnalytics = () => {
  const { settings } = useMyContext() as any

  useEffect(() => {
    if (!settings || !settings.googleAnalyticsCode) return
    ReactGA.initialize(settings.googleAnalyticsCode)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [settings])

  return null
}

export default GoogleAnalytics
