import React, { CSSProperties } from 'react'

interface IProps {
  label: string
  required?: boolean
  selectedValue: string | number | boolean | undefined
  options: {
    label: string
    value: string | number
    data?: any
    disabled?: boolean
  }[]
  className?: string
  style?: CSSProperties
  isHorizontal?: boolean
  hideDefault?: boolean
  choiceNoValue?: boolean
  disabled?: boolean

  setSelectedValue(value: string): void
}

const Select = ({
  setSelectedValue,
  options,
  selectedValue,
  className,
  style,
  label,
  required,
  isHorizontal,
  hideDefault,
  choiceNoValue,
  disabled,
}: IProps) =>
  isHorizontal ? (
    <div className="field is-horizontal">
      <div className="field-label is-small">
        <label className="label" htmlFor={label}>
          {required ? (
            <>
              {label}
              <span className="has-text-danger"> *</span>
            </>
          ) : (
            label
          )}{' '}
        </label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <div className={`select is-small ${className}`} style={style}>
              <select
                id={label}
                style={{ width: '100%' }}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const opt = event.target.options
                  const index = opt.selectedIndex
                  setSelectedValue(opt[index].value)
                }}
                value={selectedValue?.toString() || ''}
                required={required}
                disabled={disabled}
              >
                {!hideDefault && (
                  <option disabled value="">
                    -- {label} --
                  </option>
                )}
                {choiceNoValue && <option value="">Votre choix</option>}
                {options.map(option => (
                  <option key={option.value} value={option.value} disabled={option.disabled}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={style}>
      <label className="label is-small" htmlFor={label}>
        {required ? (
          <>
            {label}
            <span className="has-text-danger"> *</span>
          </>
        ) : (
          label
        )}
      </label>
      <div className="control">
        <div className={`select is-small ${className}`} style={style}>
          <select
            id={label}
            style={{ width: '100%' }}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const opt = event.target.options
              const index = opt.selectedIndex
              setSelectedValue(opt[index].value)
            }}
            value={selectedValue?.toString() || ''}
            required={required}
            disabled={disabled}
          >
            {!hideDefault && (
              <option disabled value="">
                -- {label} --
              </option>
            )}
            {choiceNoValue && <option value="">Votre choix</option>}
            {options.map(option => (
              <option key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )

export default Select
