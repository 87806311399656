import React from 'react'
import { useLocation } from 'react-router-dom'
import { useMyContext } from '../context/context'
import NavBar from './navbar'

const Header = () => {
  const location = useLocation()
  const { settings, event, user } = useMyContext() as any

  if (!event || location.pathname.includes('/live/speaker')) return <div />

  return (
    <div style={{ width: '100%' }}>
      {settings && settings.headerImg ? (
        <img
          alt="header"
          src={`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.headerImg}`}
          style={{ width: '100%' }}
        />
      ) : (
        <div style={{ padding: '20px 0', textAlign: 'center', marginBottom: 10 }}>{event.name}</div>
      )}
      {user && <NavBar />}
    </div>
  )
}

export default Header
