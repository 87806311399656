import { useGetTranslationsQuery } from '../api/api'
import { useMyContext } from '../context/context'

const useTranslation = () => {
  const { myContext } = useMyContext()

  const { data } = useGetTranslationsQuery(myContext)

  return data || {}
}

export default useTranslation
