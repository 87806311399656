import { ExportOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import React, { useEffect } from 'react'
import { useMyContext } from '../context/context'
import useHistoryCustom from '../hooks/useHistoryCustom'

const initBurger = () => {
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
  if ($navbarBurgers.length > 0) {
    $navbarBurgers.forEach(el => {
      el.addEventListener('click', () => {
        const { target } = el.dataset
        const $target = document.getElementById(target)
        if (!$target) return
        el.classList.toggle('is-active')
        $target.classList.toggle('is-active')
      })
    })
  }
}

const NavBar = () => {
  const history = useHistoryCustom()
  const { settings, user, logout } = useMyContext()

  useEffect(() => {
    initBurger()
  }, [user])

  if (!user) return null

  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
      style={{ backgroundColor: settings?.FO_NAVBAR_BACKGROUND_COLOR }}
    >
      <div role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </div>

      <div id="navbarBasicExample" className="navbar-menu" style={{ backgroundColor: settings?.FO_NAVBAR_BACKGROUND_COLOR }}>
        <div className="navbar-start">
          <div style={{ color: settings?.FO_MEETING_CONNECT_FROM_COLOR || 'black' }} className="navbar-item is-size-6">
            <span style={{ marginRight: 5 }}>Bienvenue</span>
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <Space>
              <Button type="primary" onClick={() => history.push('')}>
                Accueil <UserOutlined />
              </Button>
              <Button onClick={() => logout(() => history.push('/login'))}>
                Déconnexion <ExportOutlined />
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
