const formatUserExtended = (t, user, userEvent) => {
  if (!t) {
    return {}
  }

  const extended = Object.entries({
    ...(user?.extended || {}),
    ...(userEvent?.extended || {}),
  }).reduce((prev, [key, value]) => {
    if (typeof value === 'string') {
      return { ...prev, [key]: { label: t[key], value } }
    }

    if (value === true || value === false) {
      return { ...prev, [key]: { label: t[key], value: value ? 'Oui' : 'Non' } }
    }

    if (value !== null && typeof value === 'object') {
      const values = Object.entries(value)
        .filter(([, v]) => v)
        .map(([k]) => t[k])
        .join(', ')
      return { ...prev, [key]: { label: t[key], value: values } }
    }

    return prev
  }, {})

  return extended
}

export default formatUserExtended
