import React, { useState } from 'react'
import { useGetFormQuery } from '../../api/form.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'
import CompaniesList from './CompaniesList'
import CompanyCreate from './CompanyCreate'
import CompanySiren from './CompanySiren'

const Companies = () => {
  const [mode, setMode] = useState('list')
  const [company, setCompany] = useState()
  const [companyId, setCompanyId] = useState(null)

  const toCreate = newCompany => {
    setCompany(newCompany)
    setMode('create')
  }
  const toList = v => {
    setMode('list')
    setCompanyId(v)
  }
  const toSiren = () => setMode('siren')

  return (
    <div style={{ margin: '0 10px' }}>
      {mode === 'list' && <CompaniesList toSiren={toSiren} toCreate={toCreate} companyId={companyId} />}
      {mode === 'create' && <CompanyCreate toList={toList} company={company} />}
      {mode === 'siren' && <CompanySiren toCreate={toCreate} toList={toList} />}
    </div>
  )
}

export default Companies
