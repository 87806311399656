import React, { useEffect, useState } from 'react'
import { useGetEventUserQuery, useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useMyContext } from '../../context/context'
import Form from '../../shared/form'
import InputRadioCheckbox from '../../shared/form/input.radio.checkbox'
import useTypos from '../../hooks/useTypos'
import { useSteps } from './Steps'

const Typos = () => {
  const { myContext, settings } = useMyContext()
  const { data: typos } = useTypos()

  const { data: eventUser } = useGetEventUserQuery({ ...myContext })

  const [selected, setSelected] = useState(eventUser?.UserEvent?.typoId || 'null')

  const { nextStep } = useSteps()

  const [updateUser] = useUpdateEventUserMutation()

  useEffect(() => {
    if ((eventUser?.UserEvent?.typo || eventUser?.UserEvent?.typoId) && settings?.FO_MEETING_TYPO_NEXT_STEP) {
      nextStep()
    }
  }, [nextStep, settings, eventUser])

  return (
    <Form
      onSubmit={async () => {
        await updateUser({ ...myContext, eventUser: { typoId: selected === 'null' ? null : selected } }).unwrap()
        nextStep()
      }}
      disabled={settings?.FO_MEETING_TYPO_DEFAULT_HIDE ? selected === 'null' : false}
    >
      {settings?.FO_MEETING_TYPO_HTML && (
        <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_TYPO_HTML.html }} />
      )}
      <InputRadioCheckbox
        label={settings?.FO_MEETING_TYPO_LABEL}
        options={typos}
        selectedValues={[selected]}
        type="radio"
        setSelectedValue={v => {
          setSelected(v)
        }}
        required
      />
    </Form>
  )
}

export default Typos
