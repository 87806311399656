import React, { useMemo } from 'react'
import { useGetEventUserQuery, useLazyGetEventUserQuery, useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useGetFormQuery } from '../../api/form.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'
import Form from '../../shared/form/forms'
import { useSteps } from './Steps'

const Informations = () => {
  const { myContext, userId: myUserId, user, settings } = useMyContext()
  const { nextStep, userId, setUserId, NextButton, participation } = useSteps()
  const { formId } = useConf(participation)

  const { data: eventUser } = useGetEventUserQuery({ ...myContext, userId })
  const { data: me } = useGetEventUserQuery({ ...myContext })
  const { data: form } = useGetFormQuery({ ...myContext, formId })
  const [updateEventUser] = useUpdateEventUserMutation()
  const { data: meData } = useGetEventUserQuery(myContext)

  const html = useMemo(() => {
    if (participation === 5) {
      return settings?.FO_MEETING_COMPANIONS_TOP_HTML.html || settings?.FO_MEETING_INFO_HTML.html
    }
    if (participation === 2) {
      return settings?.FO_MEETING_SHOW_REPRESENTED_BY_HTML.html || settings?.FO_MEETING_INFO_HTML.html
    }
    return settings?.FO_MEETING_INFO_HTML.html
  }, [participation, settings])

  if (!form) return null

  return (
    <div>
      {html && <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: html }} />}
      <Form
        user={
          eventUser ||
          (settings?.FO_MEETING_COMPANIONS_COPY_EXTENDED ? { UserEvent: { extended: { ...(me?.UserEvent?.extended || {}) } } } : {})
        }
        form={form}
        afterSave={async id => {
          if (myUserId !== id) {
            await updateEventUser({
              ...myContext,
              userId: id,
              eventUser: {
                registeredBy: participation !== 2 ? `${user.firstName || ''} ${user.lastName || ''} (${user.id})` : null,
                isRemote: false,
                registeredFrom: localStorage.getItem('canal') || 'WEB',
                typo: meData?.UserEvent?.typo,
                typoId: meData?.UserEvent?.typoId,
              },
            })
          }
          setUserId(id)
          nextStep()
        }}
        NextButton={NextButton}
      />
    </div>
  )
}

export default Informations
