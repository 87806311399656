import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useMyContext } from '../context/context'

const useHistoryCustom = () => {
  const history = useHistory()
  const { baseUrl } = useMyContext()

  const push = useCallback((url: string, state?: any, search?: any) => history.push({ pathname: baseUrl + url, state, search }), [
    baseUrl,
    history,
  ])

  const replace = useCallback((url: string) => history.replace({ pathname: baseUrl + url }), [baseUrl, history])

  const goBack = useCallback(() => history.goBack(), [history])

  const reload = useCallback(() => history.go(0), [history])

  const historyCustom = useMemo(
    () => ({
      push,
      goBack,
      replace,
      reload,
    }),
    [goBack, push, reload, replace]
  )

  return historyCustom
}

export default useHistoryCustom
