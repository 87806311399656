import api from './api'

export const companionApi = api.injectEndpoints({
  endpoints: builder => ({
    getCompanions: builder.query({
      query: ({ domainId, userId, eventId }) => `/domains/${domainId}/events/${eventId}/companions?userId=${userId}`,
      transformResponse: (response, r, { userId }) => {
        return { ...response.data, users: response.data.users.filter(f => f.id !== userId) }
      },
      providesTags: ['Companion', 'EVENT_USER'],
    }),
    removeCompanion: builder.mutation({
      query: ({ domainId, eventId, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/companions/${userId}`,
        method: 'DELETE',
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['Companion'],
    }),
  }),
})

export const { useGetCompanionsQuery, useRemoveCompanionMutation } = companionApi
