import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Form, Modal, Select, Space } from 'antd'
import React, { useMemo } from 'react'
import {
  useCreateConferenceSlotUserMutation,
  useGetConferenceSlotsQuery,
  useRemoveConferenceSlotUserMutation,
} from '../../api/conference.api'
import { useMyContext } from '../../context/context'
import useLog from '../../hooks/useViewLog'
import { getDateTime2 } from '../../utils/date'

const { confirm } = Modal

const Conference = ({ conference, userId, disabled, form, required, quotaReached }) => {
  const { id, logo, title, description } = conference

  const { domainId, eventId, settings } = useMyContext()

  // const { data: mySlots } = useGetConferenceSlotsQuery({ domainId, eventId, conferenceId: id, userId: myUserId })
  // const { data: slots } = useGetConferenceSlotsQuery({ domainId, eventId, conferenceId: id, userId })
  const [createSlotUser, { error }] = useCreateConferenceSlotUserMutation()
  const [removeSlotUser] = useRemoveConferenceSlotUserMutation()

  const [createLog] = useLog()

  const selected = useMemo(() => conference?.slots?.find(slot => slot.isSelected), [conference])
  const mySelected = useMemo(() => conference?.slots?.find(slot => slot.isMySelected), [conference])
  const isActive = useMemo(() => conference?.slots?.find(slot => !slot.isFull), [conference])

  if (!isActive && settings?.FO_MEETING_CONFERENCES_IS_FULL_HIDE && !selected) {
    return null
  }

  return (
    <div style={{ marginBottom: 10, paddingBottom: 10, borderBottom: '1px solid #D3D3D3' }}>
      <div style={{ display: 'flex', marginBottom: 10, flexWrap: 'wrap' }}>
        {logo && <img src={logo} style={{ maxWidth: 200, marginRight: 10 }} />}
        <div>
          {title && <div style={{ fontSize: 25, fontWeight: 600, marginBottom: 10 }}>{title}</div>}
          {description && <div style={{ marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: description.html }} />}

          {!selected && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Form.Item
                name={conference.id}
                rules={[{ required: conference.isRequired || required, message: 'Vous devez choisir une plage horaire' }]}
              >
                <Select
                  style={{ width: 400, maxWidth: '60vw' }}
                  placeholder="Choisir la plage horaire"
                  options={conference?.slots?.map(slot => ({
                    label: `${getDateTime2(slot.startAt, slot.endAt)} 
          ${
            slot.quota
              ? `(${slot.quota - slot.usersTotal} ${slot.quota - slot.usersTotal > 1 ? 'places disponibles' : 'place disponible'})`
              : ''
          }`,
                    value: slot.id,
                    disabled: slot.quota ? slot.quota - slot.usersTotal <= 0 : false,
                  }))}
                  value={selected?.id || null}
                  onChange={async slotId => {
                    try {
                      createLog('REGISTER_CONFERENCE')
                      await createSlotUser({ domainId, eventId, conferenceId: id, userId, slotId }).unwrap()
                    } catch {
                      const values = form.getFieldsValue()
                      form.setFieldsValue({ ...values, [conference.id]: null })
                    }
                  }}
                  disabled={selected || disabled || (quotaReached && !selected)}
                />
              </Form.Item>
              {error?.data?.message && <Alert message={error?.data?.message} type="error" showIcon />}
            </div>
          )}

          {!selected && mySelected && (
            <Space>
              <Alert message={`Vous êtes inscrit pour le ${getDateTime2(mySelected.startAt, mySelected.endAt)}`} type="info" showIcon />
            </Space>
          )}

          {selected && (
            <Space style={{ flexWrap: 'wrap' }}>
              <Alert message={`Vous êtes inscrit pour le ${getDateTime2(selected.startAt, selected.endAt)}`} type="success" showIcon />
              <Button
                type="primary"
                onClick={() =>
                  confirm({
                    title: 'Voulez-vous vraiment annuler votre participation ?',
                    icon: <ExclamationCircleOutlined />,
                    okText: 'Oui',
                    okType: 'danger',
                    cancelText: 'Non',
                    onOk() {
                      removeSlotUser({ domainId, eventId, conferenceId: id, userId })
                    },
                  })
                }
                danger
              >
                Supprimer la participation
              </Button>
            </Space>
          )}
        </div>
      </div>
    </div>
  )
}

export default Conference
