import { useEffect, useMemo } from 'react'
import { useUpdateArticleUsersMutation } from '../../api/article.api'
import { useLazyGetEventUserQuery, useUpdateEventDayUserMutation, useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useLazyGetResumeQuery } from '../../api/v2/invoices'
import { useMyContext } from '../../context/context'
import { useSteps } from './Steps'

const Dates2 = () => {
  const { event, myContext } = useMyContext()
  const { nextStep, userId, daysId, articles, participation } = useSteps()

  const a = useMemo(() => Object.entries(articles).reduce((prev, [, articleIds]) => [...prev, ...articleIds], []), [articles])

  const [updateEventDay] = useUpdateEventDayUserMutation()
  const [updateArticles] = useUpdateArticleUsersMutation()
  const [updateEventUser] = useUpdateEventUserMutation()
  const [fetchUserEvent] = useLazyGetEventUserQuery()
  const [fetchResume] = useLazyGetResumeQuery()

  const updateEventDays = async () => {
    const [eventUser, resume] = await Promise.all([
      fetchUserEvent({ ...myContext, userId, test: null }, false).unwrap(),
      fetchResume({ ...myContext, articles: a }, false).unwrap(),
    ])

    if ([1, 4].includes(Number(participation))) {
      await updateEventUser({
        ...myContext,
        eventUser: {
          registeredFrom: localStorage.getItem('canal') || 'WEB',
          registeredBy: null,
          representedBy: null,
          responded: true,
          isCompleted: !resume.canPay,
          isRemote: participation === 1,
          extended: {
            ...(eventUser?.UserEvent?.extended || {}),
            PARTICIPATION_IS_REMOTE: participation === 1,
          },
        },
      }).unwrap()
    }

    const promises = [
      ...event.days.map(ed => {
        const find = event.days.length <= 1 ? true : daysId.find(d => Number(d) === ed.id)
        return updateEventDay({
          ...myContext,
          userId,
          dayId: ed.id,
          day: { registered: !!find, registeredAt: find ? new Date() : null },
        }).unwrap()
      }),
    ]

    await Promise.all(promises)
    await updateArticles({ ...myContext, userId, body: { days: articles } }).unwrap()
  }

  useEffect(() => {
    updateEventDays().then(() => nextStep())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Dates2
