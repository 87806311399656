import { post, get } from '../fetch'
import { getDomainId, getEventId } from '../../utils/store'

class EventMessageApi {
  constructor() {
    this.url = `/domains/${getDomainId()}/events/${getEventId()}/messages`
  }

  async create(message) {
    return post(this.url, message)
  }

  async findMe() {
    return get(`${this.url}/me`)
  }
}

export default EventMessageApi
