import { combineReducers, configureStore } from '@reduxjs/toolkit'
import api from '../api/api'
import apiV2 from '../api/v2'
import inscription from './inscription'
import loading from './loading'

const reducers = combineReducers({
  loading,
  inscription,
  [api.reducerPath]: api.reducer,
  [apiV2.reducerPath]: apiV2.reducer,
})

export const rootReducer = (state, action) => {
  if (action.type === 'api/resetApiState') {
    return reducers(undefined, action)
  }
  return reducers(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware, apiV2.middleware),
})
