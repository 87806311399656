import React, { useEffect } from 'react'
import { useMyContext } from '../../context/context'
import useWebsocket from '../../hooks/useWebsocket'
import Leaderboard from '../Leaderboard'
import LeaderboardV2 from '../LeaderboardV2'
import Poll from './poll'
import Questions from './Questions'
import WordCloud from './WordCloud'

const RightModule = ({ data, showTotalVotes, settings }) => {
  if (!data) return null
  if (!data.eventLive) return null
  if (data.eventLive.activeModule === 'poll')
    return (
      <div style={{ width: '70%', padding: '20px 40px', fontWeight: 500 }}>
        <Poll data={data} showTotalVotes={showTotalVotes} />
      </div>
    )
  if (data.eventLive.activeModule === 'question')
    return (
      <div style={{ width: '70%', margin: '20px 40px', fontWeight: 500 }}>
        <Questions data={data} showTotalVotes={showTotalVotes} />
      </div>
    )
  if (data.eventLive.activeModule === 'wordcloud') return <WordCloud data={data} />
  if (data.eventLive.activeModule === 'chat' && data.message)
    return (
      <div
        style={{
          textAlign: 'center',
          fontSize: data.message.message.length > 160 ? 40 : 50,
          fontWeight: 600,
          backgroundColor: settings?.bgPrimaryColor,
          color: settings?.textPrimaryColor,
          padding: '0 15px',
          margin: '0 10%',
        }}
      >
        {data.message.message}
      </div>
    )
  if (data.eventLive.activeModule === 'chat')
    return (
      <div
        style={{
          textAlign: 'center',
          fontSize: 50,
          fontWeight: 600,
          backgroundColor: settings?.bgPrimaryColor,
          color: settings?.textPrimaryColor,
          padding: '0 15px',
          margin: '0 10%',
        }}
      >
        Vous pouvez poser des questions
      </div>
    )
  return null
}

const ModeFullScreen = ({ showTotalVotes }) => {
  const [data] = useWebsocket(true)
  const { settings, eventId, domainId } = useMyContext()

  useEffect(() => {
    const doc = document.getElementsByTagName('html')[0]
    doc.style['overflow-y'] = 'hidden'
    return () => {
      doc.style['overflow-y'] = 'scroll'
    }
  }, [])

  if (data?.eventLive?.activeModule === 'question' && data?.eventLive?.showLeaderboard) return <LeaderboardV2 />
  if (data?.eventLive?.activeModule === 'question' && data?.eventLive?.showLeaderboardV2) return <Leaderboard />

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
      }}
    >
      {settings?.pollBackground && (
        <img
          src={`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.pollBackground}`}
          style={{ width: '100%' }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <RightModule data={data} showTotalVotes={showTotalVotes} settings={settings} />
        {data?.eventLive?.activeModule === 'chat' && showTotalVotes && (
          <div style={{ marginTop: 20, fontSize: 40, fontWeight: 700, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {data?.eventLive?.extended?.messageIntern && (
              <div
                style={{
                  background: 'red',
                  color: 'white',
                  textAlign: 'center',
                  padding: 16,
                  fontSize: 20,
                }}
              >
                {data?.eventLive?.extended?.messageIntern}
              </div>
            )}
            {/* <div>Questions reçues : {data?.messages?.length || 0}</div> */}
            <div>Questions à traiter : {data?.messages?.filter(m => m.favorite && !m.archived).length || 0}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ModeFullScreen
