import React from 'react'

interface ISuccess {
  isSuccess: boolean
  message: string
}

const Success = ({ isSuccess, message }: ISuccess) => {
  if (isSuccess) {
    return (
      <article className="message is-success field">
        <div className="message-body">{message}</div>
      </article>
    )
  }
  return null
}

export default Success
