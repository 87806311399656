import { useLocation } from 'react-router-dom'
import { useMyContext } from '../context/context'
import { useGetTyposQuery } from '../api/v2/typo'

const useConfOffline = () => {
  const location = useLocation()
  const { settings, myContext } = useMyContext()

  const { data: typosV2 } = useGetTyposQuery(myContext)

  const typoIdPath = location.pathname.split('/')[1].split('+')[1]
  const typo = typosV2?.find(f => f.id === Number(typoIdPath))
  const typoSettings = typo ? settings?.typosV2?.[typo.id] : {}

  const LOGIN_HTML = typoSettings?.homeMessage || settings?.homeMessage

  return { LOGIN_HTML, typo }
}

export default useConfOffline
