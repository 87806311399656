import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { useMyContext } from '../../context/context'

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  label: string
  disabled?: boolean
  type?: string
  submit?: boolean
  style?: CSSProperties
  loading?: boolean
  icon?: string
}

const Button = ({ onClick, label, disabled, type = 'primary', submit, style, loading: forceLoading, icon }: IProps) => {
  const { settings } = useMyContext() as any

  const [loading, setLoading] = useState(false)
  const isMountedRef = useRef<boolean | null>(null)

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (typeof forceLoading !== 'undefined') setLoading(forceLoading)
  }, [forceLoading])

  return (
    <button
      style={{
        backgroundColor: settings?.bgPrimaryColor,
        color: settings?.textPrimaryColor,
        ...style,
      }}
      title={label}
      type={submit ? 'submit' : 'button'}
      className={`button is-small is-${type} ${loading && 'is-loading'}`}
      onClick={async event => {
        setLoading(true)
        if (onClick) await onClick(event)
        if (isMountedRef.current) setLoading(false)
      }}
      disabled={disabled}
    >
      {icon ? (
        <span className="icon">
          <i className={`fas ${icon}`} />
        </span>
      ) : (
        label
      )}
    </button>
  )
}

export default Button
