import apiV2 from '.'

export const participationsApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    getFlagEvent: builder.query({
      query: ({ domainId, eventId, userId }) => `/domains/${domainId}/events/${eventId}/users/${userId}/flag-event`,
      transformResponse: response => response.data,
    }),
  }),
})

export const { useGetFlagEventQuery } = participationsApi
