import { IInscription } from '../services/types'

export const NEXT_STEP = 'NEXT_STEP'
export const PREV_STEP = 'PREV_STEP'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_COMPANY_ID = 'SET_COMPANY_ID'
export const SET_CREATION_MODE = 'SET_CREATION_MODE'
export const RESET = 'RESET'
export const SET_DAYS_ID = 'SET_DAYS_ID'
export const SET_PARTICIPATION_MODE = 'SET_PARTICIPATION_MODE'

export const nextStep = () => ({
  type: NEXT_STEP,
})

export const prevStep = () => ({
  type: PREV_STEP,
})

export const setUserId = (userId: number | null, next = true) => ({
  type: SET_USER_ID,
  userId,
  next,
})

export const setCompanyId = (companyId: number) => ({
  type: SET_COMPANY_ID,
  companyId,
})

export const setDaysIdAndNextStep = (daysId: number[]) => ({
  type: SET_DAYS_ID,
  daysId,
})

export const setCreationMode = () => ({
  type: SET_CREATION_MODE,
})

export const setParticipationMode = (participationMode: number) => ({
  type: SET_PARTICIPATION_MODE,
  participationMode,
})

export const reset = () => ({
  type: RESET,
})

const initState = {
  userId: null,
  companyId: null,
  stepId: 1,
  creationMode: false,
  daysId: [],
  participationMode: 1,
}

export function reducer(state: IInscription = initState, { type, ...action }: any) {
  switch (type) {
    case NEXT_STEP:
      return { ...state, stepId: state.stepId + 1, creationMode: false }
    case PREV_STEP:
      return { ...state, stepId: state.stepId - 1, creationMode: false }
    case SET_USER_ID:
      return {
        ...state,
        userId: action.userId,
        stepId: action.next ? state.stepId + 1 : state.stepId,
        creationMode: false,
      }
    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.companyId,
        stepId: state.stepId + 1,
        creationMode: false,
      }
    case SET_DAYS_ID:
      return { ...state, daysId: action.daysId, stepId: state.stepId + 1 }
    case SET_CREATION_MODE:
      return { ...state, creationMode: !state.creationMode }
    case SET_PARTICIPATION_MODE:
      return { ...state, participationMode: action.participationMode }
    case RESET:
      return initState
    default:
      return state
  }
}

export default reducer
