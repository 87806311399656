import { postWithSignal, post, get, put } from '../fetch'
import { IUserCreate } from '../../interfaces/user'
import { getDomainId } from '../../utils/store'

class UserApi {
  url: string

  constructor() {
    const domainId = getDomainId()
    this.url = `/domains/${domainId}/users`
  }

  async updateMe(user: Partial<IUserCreate>) {
    return put(`${this.url}/me`, user)
  }

  async getAll(signal: any, filters: any) {
    return postWithSignal(`${this.url}/get`, signal, filters)
  }

  async getByUserId(userId: number) {
    return get(`${this.url}/${userId}`)
  }

  async getMe() {
    return get(`${this.url}/me`)
  }

  async create(userToCreate: IUserCreate) {
    return post(this.url, userToCreate)
  }

  async update(userId: number, user: Partial<IUserCreate>) {
    return put(`${this.url}/${userId}`, user)
  }
}

export default UserApi
