import { Button, Form as Form2, Input, message } from 'antd'
import React, { useEffect } from 'react'
import { useGetUserByBadgeQuery } from '../../api/eventUser.api'
import { useGetFormQuery } from '../../api/form.api'
import { useCreateUserLinkMutation } from '../../api/userLink.api'
import { useMyContext } from '../../context/context'
import useHistoryCustom from '../../hooks/useHistoryCustom'
import useQrReader from '../../hooks/useQrReader'
import Form from '../../shared/form/forms'

const ContactsScan = () => {
  const history = useHistoryCustom()
  const [Qrcode, { code }] = useQrReader()
  const myContext = useMyContext()

  const { settings, userId } = myContext

  const { data: user } = useGetUserByBadgeQuery({ ...myContext, code })
  const { data: form } = useGetFormQuery({ ...myContext, formId: settings?.FO_MEETING_USER_LINKS_FORM_ID })
  const [createLink] = useCreateUserLinkMutation()

  useEffect(() => {
    if (user) {
      createLink({ ...myContext, userIdA: userId, userIdB: user.id })
    }
  }, [createLink, myContext, user, userId])

  if (user) {
    return (
      <div>
        {form && <Form user={user} form={form} disabled hideButton />}
        <Form2
          layout="vertical"
          onFinish={async v => {
            await createLink({ ...myContext, userIdA: userId, userIdB: user.id, body: v }).unwrap()
            message.success('Ce contact a bien été ajouté à votre liste de contact !')
            history.push('')
          }}
        >
          <Form2.Item label="Commentaire" name="comment">
            <Input.TextArea />
          </Form2.Item>
          <Form2.Item>
            <Button type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Form2.Item>
        </Form2>
      </div>
    )
  }

  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: 'white',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Button type="primary" onClick={() => history.push('')} style={{ marginBottom: 20 }}>
        Retour
      </Button>
      <Qrcode />
    </div>
  )
}

export default ContactsScan
