import { Button } from 'antd'
import React, { useEffect } from 'react'
import { useMyContext } from '../../../context/context'
import { useGetMyInvoicesQuery } from '../../../api/article.api'
import { useShowInvoiceMutation } from '../../../api/v2/invoices'

function ShowButton({ invoiceId }) {
  const { myContext } = useMyContext()
  const [show, { data, isLoading }] = useShowInvoiceMutation()

  useEffect(() => {
    show({ ...myContext, invoiceId })
  }, [invoiceId, myContext, show])

  return (
    <Button type="primary" size="small" href={data} disabled={!data} target="_blank" loading={isLoading}>
      Télécharger
    </Button>
  )
}

export default function Invoices() {
  const { myContext, settings } = useMyContext()

  const { data: invoices } = useGetMyInvoicesQuery(myContext)

  return (
    invoices?.length > 0 &&
    !settings?.FO_MEETING_INVOICES_HIDE && (
      <div>
        <div style={{ textAlign: 'center', backgroundColor: '#D9D9D9', fontWeight: 700 }}>Téléchargement de facture(s)</div>
        <div style={{ padding: 20, backgroundColor: '#F4F5F5', display: 'flex', flexDirection: 'column', gap: 16 }}>
          {invoices?.map(invoice => (
            <div style={{ display: 'flex', gap: 8 }}>
              <div>
                Fact {invoice.label} ({invoice.price} € H.T.)
              </div>
              <ShowButton invoiceId={invoice.id} />
            </div>
          ))}
        </div>
      </div>
    )
  )
}
