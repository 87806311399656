import qs from 'query-string'
import api from './api'

export const articleApi = api.injectEndpoints({
  endpoints: builder => ({
    getArticles: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/articles`,
      transformResponse: response => response.data,
      providesTags: ['Articles'],
    }),
    getInvoicesResume: builder.query({
      query: ({ domainId, eventId, body }) => `/v2/domains/${domainId}/events/${eventId}/invoicesV2/resume?${qs.stringify(body)}`,
      transformResponse: response => response.data,
      providesTags: ['Articles', 'EVENT_USER', 'Invoices', 'Companion', 'PromoCodes', 'ArticleUsers'],
    }),
    getLastInvoice: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/invoicesV2/last`,
      transformResponse: response => response.data?.invoice,
      providesTags: ['Invoices'],
    }),
    getMyInvoices: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/invoicesV2/me`,
      transformResponse: response => response.data?.invoices,
      providesTags: ['Invoices'],
    }),
    downloadInvoices: builder.mutation({
      query: ({ domainId, eventId, invoiceId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/invoicesV2/download?invoiceId=${invoiceId}`,
        method: 'POST',
      }),
      transformResponse: response => response.data,
    }),
    createInvoice: builder.mutation({
      query: ({ domainId, eventId, body }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/invoicesV2`,
        method: 'POST',
        body,
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['Invoices'],
    }),
    setPromoCode: builder.mutation({
      query: ({ domainId, eventId, code }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/invoicesV2/promo-codes`,
        method: 'PUT',
        body: { code },
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['PromoCodes'],
    }),
    updateArticleUsers: builder.mutation({
      query: ({ domainId, eventId, userId, body }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/articles/users/${userId}`,
        method: 'PUT',
        body,
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['ArticleUsers'],
    }),
    getArticleUsers: builder.query({
      query: ({ domainId, eventId, userId, isSelected }) =>
        `/v2/domains/${domainId}/events/${eventId}/articles/users/${userId}?${qs.stringify({ isSelected })}`,
      transformResponse: response => response.data,
      providesTags: ['ArticleUsers'],
    }),
  }),
})

export const {
  useGetArticlesQuery,
  useGetInvoicesResumeQuery,
  useCreateInvoiceMutation,
  useSetPromoCodeMutation,
  useUpdateArticleUsersMutation,
  useGetArticleUsersQuery,
  useGetLastInvoiceQuery,
  useGetMyInvoicesQuery,
  useDownloadInvoicesMutation,
} = articleApi
