import apiV2 from '.'

export const usersApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    getMyAssociates: builder.query({
      query: ({ domainId, eventId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/associates/me`,
        method: 'GET',
      }),
      transformResponse: response => response.data.users,
      providesTags: ['USERS'],
    }),
  }),
})

export const { useGetMyAssociatesQuery, useLazyGetMyAssociatesQuery } = usersApi
