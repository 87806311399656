import { AutoComplete, Button, Form } from 'antd'
import React, { useCallback, useState } from 'react'
import { useCreateEmployeeMutation, useGetCompaniesQuery } from '../../api/company.api'
import { useMyContext } from '../../context/context'
import useConf from '../../hooks/useConf'

const CompanySiren = ({ toCreate, toList }) => {
  const [siren, setSiren] = useState('')
  const { domainId, userId } = useMyContext()
  const { companyIndexSize, companyIndexLabel, COMPANIES_INDEX_HTML } = useConf()

  const { data: companies } = useGetCompaniesQuery({ domainId, siren })
  const [createEmployee] = useCreateEmployeeMutation()

  const onSelect = async (_, { data }) => {
    await createEmployee({ domainId, body: { userId, companyId: data.id } })
    toList(data.id)
  }

  const onCreate = useCallback(
    v => {
      toCreate({ siren: v.siren })
    },
    [toCreate]
  )

  return (
    <div>
      <div style={{ height: 16 }} />
      <Button type="primary" onClick={toList}>
        Retour
      </Button>
      {COMPANIES_INDEX_HTML ? (
        <div style={{ padding: 16 }} dangerouslySetInnerHTML={{ __html: COMPANIES_INDEX_HTML.html }} />
      ) : (
        <div style={{ height: 16 }} />
      )}
      <Form onFinish={onCreate} layout="vertical">
        <Form.Item
          label={`${companyIndexLabel || 'SIREN de la société'} (la taille doit être de ${companyIndexSize || 9})`}
          name="siren"
          rules={[
            { len: companyIndexSize || 9, message: `La taille doit être de ${companyIndexSize || 9}` },
            { pattern: new RegExp('^[0-9]+$'), message: 'Le champ doit contenir uniquement des chiffres' },
            { required: true, message: 'Ce champ est obligatoire' },
          ]}
        >
          <AutoComplete
            options={
              siren ? companies.map(company => ({ label: `${company.name} (${company.siren})`, value: company.id, data: company })) : []
            }
            onSelect={onSelect}
            onSearch={setSiren}
          />
        </Form.Item>
        <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
          <Button type="primary" htmlType="submit">
            Suivant
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default CompanySiren
