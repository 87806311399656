import { post, put, get, del } from '../fetch'
import { IFormCreate } from '../../interfaces/form'
import { getDomainId, getEventId } from '../../utils/store'

class FormApi {
  url: string

  constructor() {
    const domainId = getDomainId()
    const eventId = getEventId()
    this.url = eventId ? `/domains/${domainId}/events/${eventId}/forms` : `/domains/${domainId}/forms`
  }

  async getAll() {
    return get(this.url)
  }

  async getById(formId: number) {
    return get(`${this.url}/${formId}`)
  }

  async createOne(form: IFormCreate) {
    return post(this.url, form)
  }

  async updateById(formId: number, form: Partial<IFormCreate>) {
    return put(`${this.url}/${formId}`, form)
  }

  async deleteById(formId: number) {
    return del(`${this.url}/${formId}`)
  }
}

export default FormApi
