import { useMemo } from 'react'
import { useGetEventUserQuery } from '../api/eventUser.api'
import { useMyContext } from '../context/context'

const useConf = participation => {
  const { settings, myContext } = useMyContext()

  const { data: eventUser } = useGetEventUserQuery(myContext)

  const userTypo = useMemo(() => (eventUser?.UserEvent?.typoId ? settings?.typosV2?.[eventUser?.UserEvent?.typoId] : null), [
    eventUser,
    settings,
  ])

  const companionsMax = userTypo?.COMPANIONS_MAX || settings.FO_MEETING_COMPANIONS_MAX

  const showRepresentedBy =
    userTypo?.SHOW_REPRESENTED_BY || settings?.FO_MEETING_SHOW_REPRESENTED_BY || eventUser?.extended?.SHOW_REPRESENTED_BY

  const showCompanions = userTypo ? userTypo.COMPANIONS_IS_ACTIVE : settings.FO_MEETING_COMPANIONS_IS_ACTIVE

  const showConferences = userTypo ? userTypo.CONFERENCES_IS_ACTIVE : settings.FO_MEETING_CONFERENCES_IS_ACTIVE

  const showInfo2 = userTypo ? userTypo.FO_MEETING_INFO_2_IS_ACTIVE : settings.FO_MEETING_INFO_2_IS_ACTIVE
  const info2 = userTypo ? userTypo.FO_MEETING_INFO_2_FORM_ID : settings?.FO_MEETING_INFO_2_FORM_ID

  const articlesActive = userTypo?.FO_MEETING_ARTICLES_IS_ACTIVE || settings?.FO_MEETING_ARTICLES_IS_ACTIVE
  const articlesHide = settings?.FO_MEETING_ARTICLES_AUTO

  const companyIndexLabel = userTypo ? userTypo.COMPANY_INDEX_LABEL : settings?.COMPANY_INDEX_LABEL
  const companyIndexSize = userTypo ? userTypo.COMPANY_INDEX_MAX_SIZE : settings?.COMPANY_INDEX_MAX_SIZE
  const companiesShow = userTypo ? userTypo.FO_MEETING_COMPANIES_SHOW : settings?.FO_MEETING_COMPANIES_SHOW
  const companiesFormId = userTypo ? userTypo.FO_MEETING_COMPANIES_FORM_ID : settings?.FO_MEETING_COMPANIES_FORM_ID

  const confirmationAccountId = userTypo?.CONFIRMATION_ACCOUNT_ID
  const confirmationTemplateId = userTypo?.CONFIRMATION_TEMPLATE_ID

  const badgeId = userTypo?.BADGE_ID || settings.badgeId

  const showParticipation = !settings?.FO_MEETING_PARTICIPATON_AUTO_REGISTER

  const HOME_HTML = userTypo?.FO_MEETING_HOME || settings?.FO_MEETING_HOME

  const COMPANIES_INDEX_HTML = userTypo?.FO_MEETING_COMPANIES_INDEX_HTML || settings?.FO_MEETING_COMPANIES_INDEX_HTML
  const COMPANIES_LABEL = settings?.FO_MEETING_COMPANIES_LABEL

  const QUOTAS_SHOW = settings?.FO_MEETING_QUOTAS_SHOW && (participation === 5 ? !settings?.FO_MEETING_COMPANIONS_COPY_EXTENDED : true)

  const formId = useMemo(() => {
    if (participation === 2) {
      return settings?.FO_MEETING_REPRESENTED_FORM || settings?.formId
    }

    if (participation === 5) {
      return (
        eventUser?.UserEvent?.extended?.FORM_ID || userTypo?.COMPANION_FORM_ID || settings.FO_MEETING_COMPANION_FORM_ID || settings?.formId
      )
    }

    return eventUser?.UserEvent?.extended?.FORM_ID || userTypo?.FORM_ID || settings?.formId
  }, [eventUser, participation, settings, userTypo])

  const formIdUser = useMemo(() => {
    return eventUser?.UserEvent?.extended?.FORM_ID || userTypo?.FORM_ID || settings?.formId
  }, [eventUser, settings, userTypo])

  const showSection = id => (userTypo ? userTypo[`SECTION_${id}_SHOW`] : settings?.[`SECTION_${id}_SHOW`])

  return {
    showCompanions,
    companionsMax,
    showRepresentedBy,
    showConferences,
    formId,
    badgeId,
    articlesActive,
    articlesHide,
    formIdUser,
    confirmationTemplateId,
    confirmationAccountId,
    showInfo2,
    info2,
    companyIndexLabel,
    companyIndexSize,
    companiesShow,
    companiesFormId,
    showParticipation,
    showSection,
    HOME_HTML,
    COMPANIES_INDEX_HTML,
    COMPANIES_LABEL,
    QUOTAS_SHOW,
  }
}

export default useConf
