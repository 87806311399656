import { get, put, postWithSignal } from '../fetch'
import { IUserEventDay } from '../../interfaces/userEventDay'
import { getDomainId, getEventId } from '../../utils/store'

class UserEventDayApi {
  url: string

  constructor(dayId: number) {
    const domainId = getDomainId()
    const eventId = getEventId()
    this.url = `/domains/${domainId}/events/${eventId}/days/${dayId}/users`
  }

  async getAll(signal: any, filters: any) {
    return postWithSignal(`${this.url}/get`, signal, filters)
  }

  async getOne(userId: number) {
    return get(`${this.url}/${userId}`)
  }

  async updateOrCreateOne(userId: number, userEvent: Partial<IUserEventDay>) {
    return put(`${this.url}/${userId}`, userEvent)
  }
}

export default UserEventDayApi
