import {
  AudioOutlined,
  AuditOutlined,
  DownloadOutlined,
  PictureOutlined,
  RightCircleFilled,
  ScanOutlined,
  SoundOutlined,
  TeamOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'
import { Badge, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useDownloadBadgeMutation } from '../api/api'
import { useGetInvoicesResumeQuery } from '../api/article.api'
import { useGetEventUserQuery } from '../api/eventUser.api'
import { useGetMyContactsQuery } from '../api/userLink.api'
import { useMyContext } from '../context/context'
import useConf from '../hooks/useConf'
import useHistoryCustom from '../hooks/useHistoryCustom'
import useLog from '../hooks/useViewLog'
import ROUTES from '../services/routes'
import { useGetSectionsQuery } from '../api/v2/section'
import { useGetCompanionsQuery } from '../api/companion.api'
import { useLazyGetConferencesQuery } from '../api/conference.api'
import RecapPDF from '../components/RecapPDF/RecapPDF'
import useTranslation from '../hooks/useTranslation'

function Item({ route, title, Icon, onClick, badgeCount, iconUrl }) {
  const history = useHistoryCustom()
  const { settings } = useMyContext()

  function IconSwitch() {
    if (iconUrl) {
      return <img src={iconUrl} style={{ height: 30 }} />
    }

    if (Icon) {
      return <Icon style={{ fontSize: 30, color: settings.bgPrimaryColor }} />
    }

    return <SoundOutlined style={{ fontSize: 30, color: settings.bgPrimaryColor }} />
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid black',
        cursor: 'pointer',
        width: 400,
        padding: 16,
      }}
      onClick={() => (onClick ? onClick() : history.push(route))}
    >
      <Badge count={badgeCount} offset={[10]}>
        <Space>
          <IconSwitch />
          <div>{title}</div>
        </Space>
      </Badge>

      <RightCircleFilled style={{ fontSize: 20, color: settings.bgPrimaryColor }} />
    </div>
  )
}

function Home() {
  const [me, setMe] = useState()

  const { myContext, settings, user, userId, event } = useMyContext()
  const { badgeId, articlesActive, showSection, HOME_HTML } = useConf()
  const [createLog] = useLog('VIEW_HOME')
  const history = useHistoryCustom()
  const t = useTranslation()

  const { data: contacts } = useGetMyContactsQuery({ ...myContext })
  const { data: resume } = useGetInvoicesResumeQuery(myContext)
  const [downloadBadgeV2] = useDownloadBadgeMutation()
  const { data: sections } = useGetSectionsQuery(myContext)
  const { data: companions } = useGetCompanionsQuery(myContext)
  const { data: meData } = useGetEventUserQuery(myContext)
  const [getConferences] = useLazyGetConferencesQuery()

  useEffect(() => {
    if (meData) {
      getConferences({ ...myContext, userId: meData.id })
        .unwrap()
        .then(async c => {
          const qrcode = await QRCode.toDataURL(meData?.UserEvent?.code || '123')
          setMe({ ...meData, conferences: c, qrcode })
        })
    }
  }, [getConferences, meData, myContext])

  return (
    <div>
      {HOME_HTML && <div style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: HOME_HTML.html }} />}
      <div style={{ padding: 25, border: '25px solid #F5F6F5' }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 16,
          }}
        >
          {!settings?.FO_MEETING_PARTICIPATON_HIDE && (
            <Item
              route={ROUTES.EVENTS_INSCRIPTION}
              title={settings?.FO_MEETING_PARTICIPATON_LABEL || 'Mon inscription'}
              Icon={AuditOutlined}
              iconUrl={settings?.FO_MEETING_PARTICIPATON_ICON_URL}
            />
          )}

          {settings?.FO_MEETING_ACTIVE_CONFERENCE && user?.UserEvent?.registered && (
            <Item
              route={`/users/${userId}/conferences`}
              title={`Mes ${settings?.FO_MEETING_CONFERENCES_LABEL || 'conférences'}`}
              Icon={AudioOutlined}
              iconUrl={settings?.FO_MEETING_CONFERENCES_ICON_URL}
            />
          )}

          {settings?.FO_PARTICIPATIONS_PDF_IS_ACTIVE &&
            !settings?.hideDownloadBadgeAfterRegister &&
            companions &&
            event &&
            me &&
            user.UserEvent?.registered &&
            (resume?.canDownload || !articlesActive) && (
              <PDFDownloadLink
                document={<RecapPDF event={event} settings={settings} me={me} companions={companions} t={t} />}
                fileName={`Récapitulatif_${me.firstName || ''}-${me.lastName || ''}.pdf`}
              >
                <Item
                  title={settings?.FO_MEETING_BADGE_RECAP_LABEL || 'Mon badge'}
                  Icon={DownloadOutlined}
                  iconUrl={settings?.FO_MEETING_BADGE_RECAP_ICON_URL}
                  onClick={() => null}
                />
              </PDFDownloadLink>
            )}

          {badgeId && !settings?.hideDownloadBadgeAfterRegister && user.UserEvent?.registered && (resume?.canDownload || !articlesActive) && (
            <Item
              title={settings?.FO_MEETING_BADGE_LABEL || 'Mon badge'}
              Icon={DownloadOutlined}
              onClick={() => {
                if (companions?.users?.length > 0) {
                  history.push(ROUTES.EVENTS_INSCRIPTION)
                } else {
                  downloadBadgeV2({ ...myContext, userId: user.id, badgeId: Number(badgeId) })
                    .unwrap()
                    .then(url => window.open(url, '_blank'))
                    .then(() => createLog('DOWNLOAD_BADGE'))
                }
              }}
              iconUrl={settings?.FO_MEETING_BADGE_ICON_URL}
            />
          )}

          {!settings?.FO_MEETING_ACCESS_LIVE_BUTTON_HIDE && (
            <Item route={ROUTES.LIVE} title="Accès au Live" Icon={YoutubeOutlined} iconUrl={settings?.FO_MEETING_LIVE_ICON_URL} />
          )}
          {settings?.FO_MEETING_SHOW_PHOTOS && (
            <Item route={ROUTES.PHOTOS} title="Mes photos" Icon={PictureOutlined} iconUrl={settings?.FO_MEETING_PHOTOS_ICON_URL} />
          )}
          {settings?.FO_MEETING_USER_LINKS_IS_ACTIVE && (
            <Item
              route="/contacts/new"
              title={settings?.FO_MEETING_USER_LINKS_BUTTON_SCAN_TEXT || 'Scanner un badge'}
              Icon={ScanOutlined}
              iconUrl={settings?.FO_MEETING_SCAN_BADGE_ICON_URL}
            />
          )}
          {settings?.FO_MEETING_USER_LINKS_IS_ACTIVE && (
            <Item
              route="/contacts"
              title={settings?.FO_MEETING_USER_LINKS_BUTTON_CONTACTS_TEXT || 'Voir mes contacts'}
              Icon={TeamOutlined}
              badgeCount={contacts?.length}
              iconUrl={settings?.FO_MEETING_CONTACTS_ICON_URL}
            />
          )}

          {sections?.map(m => (showSection(m.id) ? <Item route={`/sections/${m.id}`} title={m.title} iconUrl={m.iconUrl} /> : null))}
        </div>
      </div>
    </div>
  )
}

export default Home
