import apiV2 from '.'

export const typoApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    getTypos: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/events/${eventId}/typos`,
      transformResponse: response => response.data?.typos || [],
      providesTags: ['TYPO'],
    }),
  }),
})

export const { useGetTyposQuery } = typoApi
