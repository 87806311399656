class Token {
  static get() {
    return localStorage.getItem('token')
  }

  static set(token) {
    return localStorage.setItem('token', token)
  }
}

export default Token
