import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getApiUrl } from '../services/fetch'
import Token from '../utils/Token'

const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(),
    prepareHeaders: headers => {
      const token = Token.get()
      if (token) headers.set('authorization', `Bearer ${token}`)
      return headers
    },
  }),
  tagTypes: ['EVENT_USER', 'FORM', 'Company', 'User'],
  endpoints: builder => ({
    getEvent: builder.query({
      query: ({ domainId, eventId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}`,
        method: 'GET',
      }),
      transformResponse: response => response.data,
    }),
    getTranslations: builder.query({
      query: ({ domainId }) => ({
        url: `/v2/domains/${domainId}/translations`,
        method: 'GET',
      }),
      transformResponse: response => response.data,
    }),
    getContext: builder.query({
      query: context => `/context/${context}`,
      transformResponse: response => response.data,
    }),
    downloadBadge: builder.mutation({
      query: ({ domainId, eventId, userId, badgeId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/badges/download?userId=${userId}&badgeId=${badgeId}`,
        method: 'POST',
      }),
      transformResponse: response => response.data,
    }),
    getDownloadBadge: builder.query({
      query: ({ domainId, eventId, userId, badgeId, badge }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/badges/download?userId=${userId}&badgeId=${badgeId}`,
        method: 'POST',
        body: { badge },
      }),
      transformResponse: response => response.data,
    }),
  }),
})

export const { useGetEventQuery, useGetContextQuery, useGetTranslationsQuery, useDownloadBadgeMutation, useGetDownloadBadgeQuery } = api
export const { resetApiState } = api.util

export default api
