import { Button, Typography } from 'antd'
import React, { useMemo } from 'react'
import dateFormat from 'dateformat'
import { useMyContext } from '../../context/context'

const { Text } = Typography

function Header() {
  const { event, settings } = useMyContext()

  const firstDay = useMemo(() => (event.days.length > 0 ? event.days[0] : null), [event])
  const lastDay = useMemo(() => (event.days.length > 1 ? event.days[event.days.length - 1] : null), [event])

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text style={{ fontSize: 18, marginRight: 10 }} strong>
            {`${event.name} (${event.location})`}
          </Text>
          {/* <Button
            type="link"
            onClick={() => window.open(`${settings?.WEBSITE_URL || 'https://meeting.gayakoa.com'}/${event?.alias}`, '_blank')}
          >
            Lien vers le site
          </Button> */}
        </div>
        <Text style={{ fontSize: 18, marginLeft: 10 }} strong>
          {`${dateFormat(firstDay.day, 'dddd d mmmm')} ${lastDay ? `- ${dateFormat(lastDay.day, 'dddd d mmmm')}` : ''}`}
        </Text>
      </div>
    </div>
  )
}

export default Header
