import api from './api'

export const formApi = api.injectEndpoints({
  endpoints: builder => ({
    getForm: builder.query({
      query: ({ domainId, eventId, formId }) => `/domains/${domainId}/events/${eventId}/forms/${formId}`,
      transformResponse: response => response.data,
      providesTags: ['FORM'],
    }),
  }),
})

export const { useGetFormQuery } = formApi
