import Token from '../utils/Token'

export const getApiUrl = () => {
  const host = window.location.hostname
  if (host === 'localhost') return 'http://localhost:3001'
  if (host.includes('dev') || host.includes('d1cwsl2gmmbvf6.cloudfront.net')) return 'https://api.dev.gayakoa.com'
  return 'https://api.gayakoa.com'
}

const defaultBasePath = getApiUrl()

export async function fetchAsync(method: 'GET' | 'POST' | 'DELETE' | 'PUT', url: string, body?: any) {
  const headers: any = { 'Content-Type': 'application/json; charset=utf-8' }
  headers.Authorization = `Bearer ${Token.get()}`

  const response = await window.fetch(`${defaultBasePath}${url}`, {
    method,
    headers,
    body: body && JSON.stringify(body),
  })

  const result = await response.json()
  if (!response.ok) throw result
  return result.data
}

export async function fetchAsyncWithSignal(method: 'GET' | 'POST' | 'DELETE' | 'PUT', url: string, signal: any, body?: any) {
  const headers: any = { 'Content-Type': 'application/json; charset=utf-8' }
  headers.Authorization = `Bearer ${Token.get()}`
  const response = await window.fetch(`${defaultBasePath}${url}`, {
    method,
    headers,
    signal,
    body: body && JSON.stringify(body),
  })

  const result = await response.json()
  if (response.status === 500) throw new Error('Une erreur est survenue, veuillez contacter le support.')
  if (!response.ok) throw result
  return result.data
}

export function postWithSignal(url: string, signal: any, body: any) {
  return fetchAsyncWithSignal('POST', url, signal, body)
}

export function get(url: string) {
  return fetchAsync('GET', url)
}

export function post(url: string, body?: any) {
  return fetchAsync('POST', url, body)
}

export function put(url: string, body?: any) {
  return fetchAsync('PUT', url, body)
}

export function del(url: string, body?: any) {
  return fetchAsync('DELETE', url, body)
}

export const fetchSignedUrl = async (method: 'GET' | 'PUT', url: string, file?: File | Blob) => {
  if (file)
    return fetch(url, {
      method,
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    })
  return fetch(url, { method })
}
