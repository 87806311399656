import React, { useState } from 'react'
import { useGetStatsQuery } from '../../api/stats.api'
import RemoteSelect from '../../components/RemoteSelect'
import { useMyContext } from '../../context/context'

const styles = {
  card: { flexGrow: 1, backgroundColor: '#F0F0F0', padding: 10, minWidth: 200, margin: 5 },
  cardHeader: settings => ({ borderBottom: `1px solid ${settings.backgroundColor || 'black'}`, marginBottom: 5, paddingBottom: 5 }),
  categoryTitle: { fontWeight: 700, textAlign: 'center' },
  categoryTotal: { fontWeight: 800, textAlign: 'center', fontSize: 20 },
  detailsText: { fontSize: 14 },
}

function Global() {
  const [isRemote, setIsRemote] = useState(undefined)

  const myContext = useMyContext()

  const { data: stats } = useGetStatsQuery(
    {
      ...myContext,
      isRemote: isRemote === null ? undefined : isRemote,
    },
    { pollingInterval: 15000 }
  )

  const { settings } = myContext

  const getPercentage = (x, total) => {
    if (total && x) {
      return Math.round(((x * 100) / total) * 100) / 100
    }
    return 0
  }

  return (
    <div style={{ color: 'black' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <RemoteSelect value={isRemote} onChange={setIsRemote} />
      </div>
      <div style={{ margin: -5 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={styles.card}>
            <div style={styles.cardHeader(settings)}>
              <div style={styles.categoryTitle}>Invitations</div>
              <div style={styles.categoryTotal}>{stats?.totalInvited || 0}</div>
            </div>
            <div style={styles.detailsText}>
              Positives : {stats?.totalInvitedPositive || 0} soit {getPercentage(stats?.totalInvitedPositive, stats?.totalInvited)}%
            </div>
            <div style={styles.detailsText}>
              Négatives : {stats?.totalInvitedNegative || 0} soit {getPercentage(stats?.totalInvitedNegative, stats?.totalInvited)}%
            </div>
            <div style={styles.detailsText}>
              En attente : {stats?.totalInvitedNoResponse || 0} soit {getPercentage(stats?.totalInvitedNoResponse, stats?.totalInvited)}%
            </div>
          </div>
          <div style={styles.card}>
            <div style={styles.cardHeader(settings)}>
              <div style={styles.categoryTitle}>Inscriptions</div>
              <div style={styles.categoryTotal}>{stats?.totalRegistered || 0}</div>
            </div>
            {stats?.totalRegisteredFrom.map(({ count, from }) => (
              <div style={styles.detailsText}>
                {from || 'Aucun'} : {count} soit {getPercentage(count, stats?.totalRegistered)}%
              </div>
            ))}
          </div>
          <div style={styles.card}>
            <div style={styles.cardHeader(settings)}>
              <div style={styles.categoryTitle}>Présences</div>
              <div style={styles.categoryTotal}>{stats?.totalPresent || 0}</div>
            </div>
            <div style={styles.detailsText}>
              {stats?.totalPresentRegistered || 0} pré-inscrits soit {getPercentage(stats?.totalPresentRegistered, stats?.totalPresent)}%
            </div>
            <div style={styles.detailsText}>
              {stats?.totalPresentNotRegistered || 0} directs soit {getPercentage(stats?.totalPresentNotRegistered, stats?.totalPresent)}%
            </div>
            <div style={styles.detailsText}>{stats?.totalIsIn || 0} IN</div>
            <div style={styles.detailsText}>{stats?.totalIsOut || 0} OUT</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Global
