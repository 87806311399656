import { getDomainId, getEventId } from '../../utils/store'
import { get } from '../fetch'

class EventUserPollRightApi {
  constructor(userId) {
    this.url = `/domains/${getDomainId()}/events/${getEventId()}/users/${userId}/polls/rights`
  }

  async findOne() {
    return get(this.url)
  }
}

export default EventUserPollRightApi
