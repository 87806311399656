import qs from 'query-string'
import apiV2 from '.'

export const conferencesApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    getAssociateParticipation: builder.query({
      query: ({ domainId, eventId, oneAssociate }) =>
        `/domains/${domainId}/events/${eventId}/conferences/associate-participation?${qs.stringify({ oneAssociate })}`,
      transformResponse: response => response.data,
    }),
  }),
})

export const { useGetAssociateParticipationQuery } = conferencesApi
