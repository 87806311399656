import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { useMyContext } from '../../context/context'
import { useGetSectionsQuery } from '../../api/v2/section'

const Sections = () => {
  const { myContext } = useMyContext()
  const { sectionId } = useParams()

  const { data: sections } = useGetSectionsQuery(myContext)

  const section = useMemo(() => sections?.find(f => f.id === Number(sectionId)), [sections, sectionId])

  if (section) {
    return <div dangerouslySetInnerHTML={{ __html: section.html.html }} />
  }

  return null
}

export default Sections
