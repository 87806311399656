import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react'
import nextId from 'react-id-generator'

const Input = ({
  value,
  onChange,
  type,
  label,
  focus,
  required,
  minLength,
  maxLength,
  isHorizontal,
  disabled,
  style,
  styleInput,
  placeholder,
  isForm,
}: any) => {
  const ref = useRef<HTMLInputElement>(null)
  const id = useMemo(() => nextId('input-'), [])
  const p = useMemo(() => {
    if (placeholder === undefined) {
      if (required) return `${label}*`
      return label
    }
    return placeholder
  }, [label, placeholder, required])

  useEffect(() => {
    if (focus && ref.current) ref.current.focus()
  }, [focus])

  if (isHorizontal)
    return (
      <div style={style} className="field is-horizontal">
        <div className="field-label is-small">
          <label className="label" htmlFor={id}>
            {required ? (
              <>
                {label}
                <span className="has-text-danger"> *</span>
              </>
            ) : (
              label
            )}
          </label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                id={id}
                className="input is-small"
                type={type}
                placeholder={p}
                value={value || ''}
                onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value)}
                ref={ref}
                required={required}
                maxLength={maxLength}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div style={style} className={`${isForm && 'field'}`}>
      <label className="label is-small" htmlFor={id}>
        {required ? (
          <>
            {label}
            <span className="has-text-danger"> *</span>
          </>
        ) : (
          label
        )}
      </label>
      <div className="control">
        <input
          id={id}
          className="input is-small"
          type={type}
          placeholder={p}
          value={value || ''}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value)}
          ref={ref}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          style={styleInput}
        />
      </div>
    </div>
  )
}
export default Input
