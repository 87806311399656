import { Button, Checkbox, Select, Table } from 'antd'
import React, { useState } from 'react'
import fileDownload from 'js-file-download'
import { useGetUsersQuery, useLazyGetUsersQuery } from '../../api/user.api'
import RemoteSelect from '../../components/RemoteSelect'
import { useMyContext } from '../../context/context'
import { useGetConferencesQuery } from '../../api/conference.api'
import { getDateTime2 } from '../../utils/date'
import useTypos from '../../hooks/useTypos'

const styles = {
  title: { fontSize: 16, marginRight: 10 },
}

function Users() {
  const [pageSize, setPageSize] = useState(10)
  const [pageCurrent, setPageCurrent] = useState(1)
  const [isInvited, setIsInvited] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)
  const [isPresent, setIsPresent] = useState(false)
  const [isRemote, setIsRemote] = useState(undefined)
  const [slotId, setSlotId] = useState(undefined)
  const [typo, setTypo] = useState(undefined)

  const myContext = useMyContext()
  const { data: typos } = useTypos()

  const { data: conferences } = useGetConferencesQuery({ ...myContext, all: true, userId: undefined })
  const { data: users, isLoading } = useGetUsersQuery({
    ...myContext,
    pageSize,
    pageCurrent,
    isInvited,
    isRegistered,
    isPresent,
    isRemote,
    slotId,
    typoId: typo,
  })

  const [createExport, { isLoading: isExporting }] = useLazyGetUsersQuery()

  const { settings, event } = myContext

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap' }}>
          <div style={styles.title}>{`Liste des contacts (${users?.count || 0})`}</div>
          <Button
            type="primary"
            size="small"
            loading={isExporting}
            onClick={async () => {
              const url = await createExport({
                ...myContext,
                isInvited,
                isRegistered,
                isPresent,
                isRemote,
                createExport: true,
                formId: settings.FO_MEETING_STATS_USERS_EXPORT_FORM_ID,
                slotId,
                typo,
              }).unwrap()
              const response = await fetch(url, { cache: 'no-cache' })
              fileDownload(
                await response.blob(),
                `export-users-${event.alias}-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.xlsx`
              )
            }}
          >
            Exporter
          </Button>
          {conferences?.length > 0 && (
            <div>
              <Select
                placeholder={settings?.FO_MEETING_CONFERENCES_LABEL || 'Conférences'}
                size="small"
                style={{ width: 300 }}
                options={conferences?.map(m => ({
                  label: m.title,
                  options: m.slots.map(mm => ({ label: getDateTime2(mm.startAt, mm.endAt), value: mm.id })),
                }))}
                onChange={setSlotId}
                allowClear
              />
            </div>
          )}
          {typos?.length > 1 && (
            <div>
              <Select
                placeholder="Typologie"
                size="small"
                style={{ width: 200 }}
                options={typos}
                onChange={setTypo}
                value={typo}
                allowClear
              />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Checkbox style={{ marginRight: 4 }} value={isInvited} onChange={e => setIsInvited(e.target.checked)} />
          <div style={{ marginRight: 16 }}>Invités</div>
          <Checkbox style={{ marginRight: 4 }} value={isRegistered} onChange={e => setIsRegistered(e.target.checked)} />
          <div style={{ marginRight: 16 }}>Inscrits</div>
          <Checkbox style={{ marginRight: 4 }} value={isPresent} onChange={e => setIsPresent(e.target.checked)} />
          <div style={{ marginRight: 16 }}>Présents</div>
          <RemoteSelect value={isRemote} onChange={setIsRemote} />
        </div>
      </div>
      <Table
        size="small"
        dataSource={users?.rows}
        columns={[
          { title: 'Typo', dataIndex: ['participation', 'typoV2', 'label'] },
          { title: 'Prénom', dataIndex: 'firstName' },
          { title: 'Nom', dataIndex: 'lastName' },
          {
            title: 'Présence',
            dataIndex: ['participation', 'isRemote'],
            render: v => {
              if (v === true) {
                return 'Distanciel'
              }
              if (v === false) {
                return 'Présentiel'
              }
              return null
            },
          },
          { title: 'Invité', dataIndex: ['participation', 'invited'], render: v => (v ? 'Oui' : 'Non') },
          { title: 'Inscrit', dataIndex: ['participation', 'days'], render: v => (v.find(f => f.registered) ? 'Oui' : 'Non') },
          { title: 'Présent', dataIndex: ['participation', 'days'], render: v => (v.find(f => f.present) ? 'Oui' : 'Non') },
        ]}
        pagination={{ pageSize, current: pageCurrent, total: users?.count }}
        onChange={pagination => {
          setPageSize(pagination.pageSize)
          setPageCurrent(pagination.current)
        }}
        loading={isLoading}
      />
    </>
  )
}

export default Users
