import { Button, Form, Input } from 'antd'
import React from 'react'

const ShowPhotosWithBadge = () => {
  const onSubmit = async values => {
    window.location.href = `https://api.gayakoa.com/v2/authentications/connect-with-badge?badge=${values.badge}`
  }

  return (
    <Form style={{ width: 300, margin: '0 auto' }} layout="vertical" onFinish={onSubmit}>
      <Form.Item label="Code du badge" name="badge" rules={[{ required: true, message: 'Le code du badge est obligatoire' }]}>
        <Input />
      </Form.Item>

      <Button type="primary" htmlType="submit">
        Voir mes photos
      </Button>
    </Form>
  )
}

export default ShowPhotosWithBadge
