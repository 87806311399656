import { getDomainId, getEventId } from '../../utils/store'
import { get } from '../fetch'

class PictureApi {
  url: string

  constructor() {
    const domainId = getDomainId()
    const eventId = getEventId()
    this.url = `/domains/${domainId}/events/${eventId}/pictures`
  }

  async getAll() {
    return get(this.url)
  }

  async getLastOne() {
    return get(`${this.url}/last`)
  }
}

export default PictureApi
