import { post, put, get, del } from '../fetch'
import { IEmailTemplateCreate } from '../../interfaces/email'
import { getDomainId, getEventId } from '../../utils/store'

class EmailApi {
  url: string

  constructor() {
    const domainId = getDomainId()
    const eventId = getEventId()
    this.url = eventId ? `/domains/${domainId}/events/${eventId}/emails` : `/domains/${domainId}/emails`
  }

  async createOrUpdateTemplate(template: IEmailTemplateCreate) {
    return post(`${this.url}/templates`, template)
  }

  async deleteTemplate(id: number) {
    return del(`${this.url}/templates/${id}`)
  }

  async getActions(forExhibitor: boolean) {
    return get(`${this.url}/actions?forExhibitor=${forExhibitor}`)
  }

  async getAccounts() {
    return get(`${this.url}/accounts`)
  }

  async sendFromActionIdAndEmail(actionId: number, usersId: number[] | null, email?: string) {
    return post(`${this.url}/send`, { actionId, usersId, email })
  }

  async sendFromActionId(actionId: number, usersId: number[] | null, variables: any) {
    return post(`${this.url}/send`, { actionId, usersId, variables })
  }

  async sendFromTemplateIdAndAccountId(templateId: number, accountId: number, usersId: number[], variables?: any) {
    return post(`${this.url}/send`, { templateId, accountId, usersId, variables })
  }

  async sendFromTemplateIdAndAccountIdAndEmail(templateId: number, accountId: number, email: string) {
    return post(`${this.url}/send`, { templateId, accountId, email })
  }
}
export default EmailApi
