/* eslint-disable react/prop-types */
import React from 'react';

function Flex({ children, style }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...style }}>
      {children}
    </div>
  );
}

export default Flex;
