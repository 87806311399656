import React from 'react'
import { useGetEventUserQuery, useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useGetFormQuery } from '../../api/form.api'
import { useMyContext } from '../../context/context'
import Form from '../../shared/form/forms'
import useConf from '../../hooks/useConf'
import { useSteps } from './Steps'

const Informations2 = () => {
  const { settings, myContext, userId: myUserId, user } = useMyContext()
  const { nextStep, userId, setUserId, NextButton } = useSteps()
  const { info2 } = useConf()

  const { data: eventUser } = useGetEventUserQuery({ ...myContext, userId })
  const { data: form } = useGetFormQuery({ ...myContext, formId: info2 })
  const [updateEventUser] = useUpdateEventUserMutation()

  if (!form) return null

  return (
    <div>
      {settings?.FO_MEETING_INFO_2_HTML && (
        <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_INFO_2_HTML.html }} />
      )}
      <Form
        user={eventUser}
        form={form}
        afterSave={async id => {
          if (myUserId !== id) {
            await updateEventUser({
              ...myContext,
              userId: id,
              eventUser: {
                registeredBy: `${user.firstName || ''} ${user.lastName || ''} (${user.id})`,
                isRemote: false,
                registeredFrom: localStorage.getItem('canal') || 'WEB',
              },
            })
          }
          setUserId(id)
          nextStep()
        }}
        NextButton={NextButton}
      />
    </div>
  )
}

export default Informations2
