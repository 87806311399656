import React from 'react'
import { Button, Card, Table } from 'antd'
import fileDownload from 'js-file-download'
import useHistoryCustom from '../../hooks/useHistoryCustom'
import { useMyContext } from '../../context/context'
import { useGetFormQuery } from '../../api/form.api'
import { useGetMyContactsQuery, useLazyGetMyContactsQuery } from '../../api/userLink.api'

function Contacts() {
  const history = useHistoryCustom()
  const { myContext, event, settings } = useMyContext()

  const { data: contacts } = useGetMyContactsQuery({ ...myContext })
  const { data: form } = useGetFormQuery({ ...myContext, formId: settings?.FO_MEETING_USER_LINKS_FORM_ID })
  const [createExport] = useLazyGetMyContactsQuery()

  // const createExport = () => {
  //   try {
  //     const models = form.pages.reduce(
  //       (prev, curr) => [...prev, ...curr.models.filter(m => ['authentication', 'user'].includes(m.entity))],
  //       []
  //     )

  //     const format = user =>
  //       models.reduce((prev, curr) => {
  //         if (curr.fieldExtended) {
  //           const userValues = user.extended[curr.fieldExtended]
  //           if (!curr.values || !userValues) return { ...prev, [curr.label]: userValues }

  //           const array = Object.entries(userValues)
  //             .filter(([, v]) => v)
  //             .map(([k]) => {
  //               const find = curr.values.find(f => f.field === k)
  //               if (find) return find.label
  //               return null
  //             })

  //           return { ...prev, [curr.label]: array.join(', ') }
  //         }
  //         if (curr.entity === 'user') return { ...prev, [curr.label]: user[curr.field] }
  //         if (curr.entity === 'authentication')
  //           return { ...prev, [curr.label]: user.authentication ? user.authentication[curr.field] : null }
  //         return null
  //       }, {})

  //     json2excel({
  //       data: contacts.map(contact => ({
  //         Date: new Date(contact.linkedAt).toLocaleString(),
  //         ...format(contact),
  //         Commentaire: contact.comment,
  //       })),
  //     })
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  return (
    <div>
      <Button type="primary" onClick={() => history.push('')} style={{ marginBottom: 10 }}>
        Retour
      </Button>
      <Card
        title={`Vos contacts (${(contacts || []).length})`}
        extra={
          <Button
            type="primary"
            onClick={async () => {
              const url = await createExport({
                ...myContext,
                createExport: true,
                formId: settings.FO_MEETING_USER_LINKS_FORM_ID,
              }).unwrap()
              const response = await fetch(url, { cache: 'no-cache' })
              fileDownload(
                await response.blob(),
                `export-users-${event.alias}-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.xlsx`
              )
            }}
            disabled={!form}
          >
            Exporter
          </Button>
        }
      >
        <Table
          scroll={{ x: true }}
          dataSource={contacts}
          columns={[
            { title: 'Date', dataIndex: 'linkedAt', render: date => new Date(date).toLocaleString() },
            { title: 'Prénom', dataIndex: 'firstName', sorter: (a, b) => a.firstName.localeCompare(b.firstName) },
            { title: 'Nom', dataIndex: 'lastName', sorter: (a, b) => a.lastName.localeCompare(b.lastName) },
            { title: 'Email', dataIndex: ['authentication', 'email'] },
            { title: 'Mobile', dataIndex: 'mobile' },
            { title: 'Fixe', dataIndex: 'phone' },
          ]}
        />
      </Card>
    </div>
  )
}

export default Contacts
