import React, { useMemo, useState } from 'react'
import { useMyContext } from '../../context/context'
import { post } from '../../services/fetch'

const Poll = ({ showTotalVotes = false, data }: any) => {
  const { settings, eventId, domainId } = useMyContext() as any

  const [hasVotedForPollId, setHasVotedForPollId] = useState(null)

  const totalVotes = useMemo(() => data?.pollResults.reduce((prev: any, current: any) => prev + Number(current.votes), 0), [data])

  const totalVoters = data?.pollResults.reduce((prev: any, current: any) => prev + Number(current.voters), 0)

  const voteOrCloseOrResults = () => {
    if (data.eventLive.showResults) {
      return data.pollChoices.map((c: any) => {
        const result = data.pollResults.find((r: any) => r.id === c.id)
        const votes = result?.votes || 0
        const percentage = votes && totalVotes ? (votes * 100) / totalVotes : 0
        return (
          <div key={Math.random()}>
            <div style={{ color: 'black' }}>
              {c.label} {!data.eventLive.hideVotes && `(${votes} vote${votes > 1 ? 's' : ''} soit ${Math.floor(percentage)}%)`}
            </div>
            <div
              style={{
                animation: 'width0to100 4s ease',
                background: settings?.bgPrimaryColor,
                marginBottom: 20,
                width: `${percentage}%`,
                height: 20,
                minWidth: 1,
              }}
            />
          </div>
        )
      })
    }

    if (data.poll.isClose) {
      return (
        <div
          style={{
            textAlign: 'center',
            fontSize: 22,
            background: settings?.bgPrimaryColor,
            padding: '5px 10px',
            marginBottom: 15,
          }}
        >
          Le temps imparti est écoulé, il n’est plus possible de répondre
        </div>
      )
    }

    if (data.poll.id === hasVotedForPollId)
      return (
        <div
          style={{
            textAlign: 'center',
            fontSize: 22,
            background: settings?.bgPrimaryColor,
            padding: '5px 10px',
          }}
        >
          Votre réponse est prise en compte
        </div>
      )
    return data.pollChoices.map((c: any) => (
      <div
        key={Math.random()}
        style={{
          background: settings?.bgPrimaryColor,
          padding: '5px 10px',
          cursor: 'pointer',
          marginBottom: 20,
          textAlign: 'center',
          fontSize: '1.2em',
        }}
        onClick={async () => {
          setHasVotedForPollId(data.poll.id)
          await post(`/domains/${domainId}/events/${eventId}/polls/${data.poll.id}/pollChoices/${c.id}`)
        }}
      >
        {c.label}
      </div>
    ))
  }

  if (data.poll)
    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            marginBottom: 10,
            fontSize: 28,
            fontWeight: 700,
          }}
        >
          {data.poll.title}
        </div>
        <div style={{ textAlign: 'center', marginBottom: 20, fontSize: 22 }} dangerouslySetInnerHTML={{ __html: data.poll.label }} />
        <div style={{ color: settings?.textPrimaryColor }}>{voteOrCloseOrResults()}</div>
        {showTotalVotes && (
          <div style={{ marginTop: 20, fontSize: 44, fontWeight: 700 }}>
            <div>Nombre de votes : {totalVotes}</div>
            <div>Nombre de votants : {totalVoters}</div>
          </div>
        )}
      </div>
    )
  return null
}

export default Poll
