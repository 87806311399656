import { Spin } from 'antd'
import fileDownload from 'js-file-download'
import React, { useCallback, useEffect, useState } from 'react'
import Gallery from 'react-grid-gallery'
import { useLocation } from 'react-router-dom'
import useLog from '../../hooks/useViewLog'
import UserPhotoApi from '../../services/api/UserPhoto.api'
import { useMyContext } from '../../context/context'

const Photos = () => {
  const { settings } = useMyContext()
  const location = useLocation()

  const [show, setShow] = useState(false)
  const [pageSize, setPageSize] = useState(50)
  const [pageCurrent, setPageCurrent] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [photos, setPhotos] = useState([])
  const [loading, setLoading] = useState(true)

  const [createLog] = useLog('VIEW_PHOTOS')

  const fetchPhotos = useCallback(() => {
    const search = new URLSearchParams(location.search)
    const badge = search.get('badge')
    new UserPhotoApi().findAll({ badge, pageSize, pageCurrent }).then(p => {
      setLoading(false)
      setPhotos(pp => [...pp, ...p])
      if (p?.length > 0) {
        setShow(true)
      }
    })
  }, [location, pageCurrent, pageSize])

  const handleScroll = useCallback(() => {
    const element = document.getElementById('bottom')
    if (element && show) {
      const rect = element.getBoundingClientRect()
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0
      if (isVisible) {
        setShow(false)
        setPageCurrent(p => p + 1)
      }
    }
  }, [show])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    fetchPhotos()
  }, [fetchPhotos])

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
  }

  return (
    <div>
      {settings?.FO_MEETING_PHOTOS_MESSAGE_TOP && (
        <div style={{ marginBottom: 20 }} dangerouslySetInnerHTML={{ __html: settings?.FO_MEETING_PHOTOS_MESSAGE_TOP.html }} />
      )}
      {photos.length > 0 ? (
        <div>
          <Gallery
            images={photos.map(p => ({
              src: p.hdFilter || p.hd,
              thumbnail: p.xsFilter || p.xs,
              thumbnailHeight: p.thumbnailHeight,
              thumbnailWidth: p.thumbnailWidth,
            }))}
            enableImageSelection={false}
            backdropClosesModal
            currentImageWillChange={setCurrentIndex}
            customControls={
              currentIndex !== null
                ? [
                    <button
                      className="button is-primary"
                      onClick={async () => {
                        const photo = photos[currentIndex]
                        const url = photo.originalFilter || photo.original
                        const response = await fetch(url, {
                          cache: 'no-cache',
                        })
                        createLog('DOWNLOAD_PHOTO')
                        fileDownload(await response.blob(), `photo_${photos[currentIndex].id}.jpg`)
                      }}
                    >
                      Télécharger cette photo
                    </button>,
                  ]
                : []
            }
          />
          <div id="bottom" style={{ textAlign: 'center' }}>
            ----
          </div>
        </div>
      ) : (
        <div>
          {settings?.FO_MEETING_PHOTOS_EMPTY && <div dangerouslySetInnerHTML={{ __html: settings?.FO_MEETING_PHOTOS_EMPTY.html }} />}
        </div>
      )}
    </div>
  )
}

export default Photos
