import { ILoading } from '../services/types'

export const SET = 'SET_LOADING'

export const setLoading = (loading: boolean) => ({
  type: SET,
  payload: loading,
})

export function reducer(state: ILoading = { is: false, count: 0 }, { type, ...action }: any) {
  switch (type) {
    case SET: {
      const loading = action.payload
      const { count } = state
      if (loading) return { is: true, count: count + 1 }
      return { is: count > 1, count: count - 1 }
    }
    default:
      return state
  }
}

export default reducer
