import { Select } from 'antd'
import React, { useEffect, useState } from 'react'

export default function FormSelect({ options, onChange, value, style }) {
  const [v, setV] = useState(null)

  useEffect(() => {
    if (value) {
      setV(value)
    }
  }, [value])

  return (
    <Select
      style={style}
      value={v ? Object.keys(v)[0] : null}
      onChange={newV => {
        const vv = newV ? { [newV]: true } : null
        setV(vv)
        onChange(vv)
      }}
      options={options}
    />
  )
}
