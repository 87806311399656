import React from 'react'
import { useMyContext } from '../context/context'
import useLog from '../hooks/useViewLog'

const styles = { vimeo: { width: '100%', height: '500px' } }

function Replay() {
  const { settings } = useMyContext()

  useLog('VIEW_REPLAY')

  return (
    <>
      {settings?.replayHtml && (
        <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.replayHtml.html || settings.replayHtml }} />
      )}
      {settings?.replayVimeoUrl && (
        <iframe
          style={styles.vimeo}
          title="Replay"
          src={settings.replayVimeoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </>
  )
}

export default Replay
