import React, { ChangeEvent, useEffect, useRef } from 'react'

interface IProps {
  value: string | undefined
  onChange: (value: string) => void
  type: 'text' | 'email' | 'password'
  label: string
  focus?: boolean
  required?: boolean
  minLength?: number
}

const Input = ({
  value,
  onChange,
  type,
  label,
  focus,
  required,
  minLength,
}: IProps) => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (focus && ref.current) ref.current.focus()
  }, [focus])

  return (
    <div className="field">
      <label className="label" htmlFor={label}>
        {required ? `${label}*` : label}
      </label>
      <div className="control">
        <input
          id={label}
          className="input"
          type={type}
          placeholder={required ? `${label}*` : label}
          value={value}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
            onChange(target.value)
          }
          ref={ref}
          required={required}
          minLength={minLength}
        />
      </div>
    </div>
  )
}
export default Input
