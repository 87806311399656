import { getDomainId, getEventId } from '../../utils/store'
import { post } from '../fetch'

class EventUserQuestionResponse {
  constructor(questionId) {
    this.url = `/domains/${getDomainId()}/events/${getEventId()}/questions/${questionId}/responses`
  }

  async create(responsesId) {
    return post(`${this.url}/me`, { responsesId })
  }
}

export default EventUserQuestionResponse
