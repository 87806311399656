import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'

export default function FormCheckbox({ label, options, onChange, value, style }) {
  const [v, setV] = useState(null)

  useEffect(() => {
    if (value) {
      setV(value)
    }
  }, [value])

  return (
    <div style={{ display: 'flex', gap: 16 }}>
      <div style={{ fontWeight: 'bold' }}>{label}</div>
      <Checkbox.Group
        style={style}
        value={v ? Object.keys(v) : null}
        onChange={values => {
          const vv = values.length ? values.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}) : null
          setV(vv)
          onChange(vv)
        }}
        options={options}
      />
    </div>
  )
}
