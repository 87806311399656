import React from 'react'
import { CustomChat, FacebookProvider } from 'react-facebook'
import { useMyContext } from '../context/context'

const Facebook = () => {
  const { settings } = useMyContext() as any

  if (!settings || !settings.facebookCode) return null

  return (
    <FacebookProvider appId="164575260269084" chatSupport xfbml wait>
      <CustomChat
        pageId={settings.facebookCode}
        minimized
        loggedInGreeting="Bonjour, comment puis-je vous aider ?"
        loggedOutGreeting="Bonjour, comment puis-je vous aider ?"
      />
    </FacebookProvider>
  )
}

export default Facebook
