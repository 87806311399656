import queryString from 'query-string'
import { post, get } from '../fetch'
import { getDomainId, getEventId } from '../../utils/store'

class UserPhotoApi {
  url: string

  constructor() {
    const domainId = getDomainId()
    const eventId = getEventId()
    this.url = `/domains/${domainId}/events/${eventId}/users/photos`
  }

  async getMe() {
    return get(`${this.url}/me`)
  }

  async findAll({ badge, pageSize, pageCurrent }: any) {
    return get(`${this.url}?${queryString.stringify({ badge, pageSize, pageCurrent })}`)
  }

  async createOne() {
    return post(this.url)
  }
}

export default UserPhotoApi
