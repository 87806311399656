import { Checkbox, Divider, Form, Radio, Space } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useGetArticleUsersQuery, useGetArticlesQuery } from '../../api/article.api'
import { useMyContext } from '../../context/context'
import { getDate } from '../../utils/date'
import { useSteps } from './Steps'

function RadioOrCheck({ articles, id, onChange, value }) {
  const findRadio = articles.find(f => f.days?.[id]?.isRadio)

  if (findRadio) {
    return (
      <Radio.Group value={value ? value[0] : null} onChange={e => onChange([e.target.value])}>
        <Space direction="vertical">
          {articles?.map(article => {
            const d = article.days?.[id]
            if (!d) {
              return null
            }

            return <Radio disabled={d.isDisabled} value={article.id}>{`${article.label} (${article.price} € H.T.)`}</Radio>
          })}
        </Space>
      </Radio.Group>
    )
  }

  return (
    <Checkbox.Group value={value} onChange={onChange}>
      <Space direction="vertical">
        {articles?.map(article => {
          const d = article.days?.[id]
          if (!d) {
            return null
          }

          return d?.isShow && <Checkbox disabled={d.isDisabled} value={article.id}>{`${article.label} (${article.price} € H.T.)`}</Checkbox>
        })}
      </Space>
    </Checkbox.Group>
  )
}

const Articles = () => {
  const [form] = Form.useForm()
  const { event, myContext, settings } = useMyContext()
  const { NextButton, nextStep, daysId, setArticles, userId } = useSteps()

  const { data: articles2 } = useGetArticlesQuery(myContext)
  const { data: articlesUser } = useGetArticleUsersQuery({ ...myContext, userId, isSelected: true })

  const days = useMemo(() => {
    if (!articles2 || !event) {
      return []
    }

    return [{ id: 0 }, ...event?.days.filter(f => daysId.find(ff => f.id === ff))]
      .map(m => {
        const a = articles2.filter(f => f.days?.[m.id]?.isShow)
        return { ...m, articles: a }
      })
      .filter(f => f.articles.length > 0)
  }, [articles2, daysId, event])

  const onFinish = useCallback(
    async values => {
      setArticles(values)
      nextStep()
    },
    [nextStep, setArticles]
  )

  useEffect(() => {
    if (articlesUser && form) {
      form.setFieldsValue(articlesUser.days)
      if (settings?.FO_MEETING_ARTICLES_AUTO) {
        setArticles(articlesUser.days)
        nextStep()
      }
    }
  }, [articlesUser, form, nextStep, setArticles, settings])

  if (settings?.FO_MEETING_ARTICLES_AUTO) {
    return null
  }

  return (
    <div>
      {settings?.FO_MEETING_ARTICLES_HTML && (
        <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_ARTICLES_HTML.html }} />
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={(_, v) => {
          setArticles(v)
        }}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Space direction="vertical" split={<Divider type="horizontal" style={{ margin: 0, marginBottom: 8 }} />}>
          {days.map(({ day, id, articles }) => (
            <Form.Item
              label={day ? <div style={{ fontWeight: 700, width: 200 }}>{day ? getDate(day) : ''}</div> : null}
              name={id}
              rules={[{ required: settings?.FO_MEETING_ARTICLES_REQUIRED, message: 'Ce choix est obligatoire' }]}
            >
              <RadioOrCheck id={id} articles={articles} />
            </Form.Item>
          ))}
        </Space>
        <NextButton htmlType="submit" />
      </Form>
    </div>
  )
}

export default Articles
