import React, { FormEvent, useState } from 'react'
import { useMyContext } from '../context/context'
import useHistoryCustom from '../hooks/useHistoryCustom'
import UserApi from '../services/api/User.api'
import ROUTES from '../services/routes'
import Button from '../shared/form/button'
import Input from '../shared/input'
import MessageError from '../shared/message.error'

const PasswordNew = () => {
  const history = useHistoryCustom()
  const { user } = useMyContext() as any

  const [password, setPassword] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    if (!password) return

    setLoading(true)
    setError('')

    try {
      await new UserApi().updateMe({
        ...user,
        Authentication: { email: user.Authentication?.email || '', password },
      })
      history.replace(ROUTES.EVENTS_INSCRIPTION)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="form">
      <form onSubmit={onSubmit} className="field">
        <Input
          value={password}
          onChange={setPassword}
          type="text"
          label="Nouveau mot de passe"
          minLength={4}
          required
          focus
        />
        <MessageError message={error} />
        <Button style={{ width: '100%' }} label="Enregistrer" loading={loading} submit />
      </form>
    </div>
  )
}

export default PasswordNew
