import { Select } from 'antd'
import React from 'react'

function RemoteSelect({ value, onChange }) {
  return (
    <Select
      style={{ width: 120 }}
      options={[
        { label: 'Tous', value: null },
        { label: 'Distanciel', value: true },
        { label: 'Présentiel', value: false },
      ]}
      value={typeof value === 'undefined' ? null : value}
      onChange={v => (v === null ? onChange(undefined) : onChange(v))}
      size="small"
    />
  )
}

export default RemoteSelect
