import apiV2 from '.'

export const modelsApi = apiV2.injectEndpoints({
  endpoints: builder => ({
    updateUserModels: builder.mutation({
      query: ({ domainId, eventId, userId, userExt, participationExt }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/models`,
        method: 'PUT',
        body: { userExt, participationExt },
      }),
      invalidatesTags: ['PARTICIPATION'],
    }),
    getModels: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/events/${eventId}/models?hasQuota=true`,
      transformResponse: response => response.data.models,
      providesTags: ['MODELS'],
    }),
    getUserModels: builder.query({
      query: ({ domainId, eventId, userId }) => `/domains/${domainId}/events/${eventId}/users/${userId}/models`,
      transformResponse: response => response.data,
      providesTags: ['MODELS', 'PARTICIPATION'],
    }),
  }),
})

export const { useUpdateUserModelsMutation, useGetModelsQuery, useGetUserModelsQuery } = modelsApi
