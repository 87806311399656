import React, { useState } from 'react'
import { useSteps } from '../screens/steps/Steps'

const Form = ({ children, onSubmit, disabled }: any) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { NextButton } = useSteps() as any

  return (
    <>
      <form
        onSubmit={async e => {
          e.preventDefault()
          setLoading(true)
          await onSubmit()
          setLoading(false)
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div>{children}</div>
        <NextButton loading={loading} disabled={disabled} htmlType="submit" />
      </form>
    </>
  )
}

export default Form
