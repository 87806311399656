import { Button, Space, Table } from 'antd'
import fileDownload from 'js-file-download'
import React, { useState } from 'react'
import { useGetLogsQuery, useLazyGetLogsQuery } from '../../api/log.api'
import { useMyContext } from '../../context/context'

const styles = {
  title: { fontSize: 16 },
}

function LogsLast() {
  const [pageSize, setPageSize] = useState(10)
  const [pageCurrent, setPageCurrent] = useState(1)

  const myContext = useMyContext()

  const { data: logs, isFetching } = useGetLogsQuery({ ...myContext, pageSize, pageCurrent }, { pollingInterval: 5000 })
  const [createExport, { isLoading: isExporting }] = useLazyGetLogsQuery()

  const { event } = myContext

  return (
    <>
      <Space>
        <div style={styles.title}>Dernières actions</div>
        <Button
          type="primary"
          size="small"
          loading={isExporting}
          onClick={async () => {
            const url = await createExport({ ...myContext, createExport: true }).unwrap()
            const response = await fetch(url, { cache: 'no-cache' })
            fileDownload(
              await response.blob(),
              `export-logs-${event.alias}-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.xlsx`
            )
          }}
        >
          Exporter
        </Button>
      </Space>
      <Table
        rowKey="id"
        size="small"
        dataSource={logs?.rows}
        columns={[
          { title: 'Date', dataIndex: 'createdAt', render: v => new Date(v).toLocaleString() },
          { title: 'Action', dataIndex: 'label' },
          { title: 'Prénom', dataIndex: ['user', 'firstName'] },
          { title: 'Nom', dataIndex: ['user', 'lastName'] },
        ]}
        loading={isFetching}
        pagination={{ pageSize, current: pageCurrent, total: logs?.count }}
        onChange={pagination => {
          setPageSize(pagination.pageSize)
          setPageCurrent(pagination.current)
        }}
      />
    </>
  )
}

export default LogsLast
