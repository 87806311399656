import queryString from 'query-string'
import { IUser } from '../../interfaces/user'
import { getDomainId, getEventId } from '../../utils/store'
import { get, postWithSignal, put } from '../fetch'

class UserEventApi {
  url: string

  constructor(eventIdOption?: number) {
    const domainId = getDomainId()
    const eventId = eventIdOption || getEventId()
    this.url = `/domains/${domainId}/events/${eventId}/users`
  }

  async getAll(signal: any, filters: any) {
    return postWithSignal(`${this.url}/get`, signal, filters)
  }

  async getByUserId(userId: number) {
    return get(`${this.url}/${userId}`)
  }

  async getAllAssociates() {
    return get(`${this.url}/associates`)
  }

  async getOne(query: any) {
    return get(`${this.url}?${queryString.stringify(query)}`)
  }

  async updateOrCreateOne(userId: number, userEvent: any) {
    return put(`${this.url}/${userId}`, userEvent)
  }

  async updateOrCreateMultiple(users: Partial<IUser>[]) {
    return put(this.url, users)
  }
}

export default UserEventApi
