import React, { useCallback, useEffect, useState } from 'react'
import PictureApi from '../../services/api/Picture.api'

const randomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

function PhotoViewer() {
  const [photos, setPhotos] = useState([])
  const [photo, setPhoto] = useState(null)

  const getPhotos = useCallback(
    () =>
      new PictureApi()
        .getAll()
        .then(p => {
          if (!p.length) return
          if (p.length !== photos.length) {
            setPhoto(p[0])
            setPhotos(p)
          }
          const random = randomInt(0, p.length - 1)
          setPhoto(p[random])
        })
        .catch(() => setPhotos(null)),
    [photos.length]
  )

  useEffect(() => {
    getPhotos()

    const interval = setInterval(() => {
      getPhotos()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [getPhotos])

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [])

  return (
    <div
      style={{
        backgroundColor: 'black',
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {photo && <img src={photo.originalFilter || photo.original} style={{ width: 'auto', height: 'auto', maxHeight: '100vh' }} />}
    </div>
  )
}

export default PhotoViewer
