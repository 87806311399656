import React from 'react'
import { useLocation } from 'react-router'
import { useMyContext } from '../../context/context'
import PlayerLocal from './Player/Local'

const Video = ({ hide }) => {
  const location = useLocation()
  const { settings } = useMyContext()

  const { youtubeId, vimeoUrl } = settings

  const search = new URLSearchParams(location.search)
  const mode = search.get('mode')

  if (hide) return null

  if (mode === 'internal') return <PlayerLocal />

  if (!youtubeId && !vimeoUrl) return <div>L'id de la vidéo n'est pas renseigné</div>

  if (vimeoUrl)
    return (
      <div
        className="live-flex"
        style={{
          padding: '56.25% 15px 0',
          position: 'relative',
          width: settings?.FO_MEETING_MODULE_LIVE_BELOW ? '100%' : '50%',
          flexGrow: 1,
        }}
      >
        <iframe
          style={{
            width: 'calc(100% - 40px)',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 20,
          }}
          title="Live"
          src={vimeoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    )

  return (
    <div className="live-flex" style={{ width: settings?.FO_MEETING_MODULE_LIVE_BELOW ? '100%' : '50%', flexGrow: 1, padding: '0 15px' }}>
      <div>
        <iframe
          style={{ width: '100%', height: '60vh' }}
          title="Youtube Live"
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  )
}

export default Video
