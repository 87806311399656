import { Button, Radio, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAddEmployeeMutation, useGetCompaniesQuery, useRemoveEmployeeMutation } from '../../api/company.api'
import { useGetEventUserQuery, useUpdateEventUserMutation } from '../../api/eventUser.api'
import { useMyContext } from '../../context/context'
import { useSteps } from '../steps/Steps'
import { useGetFormQuery } from '../../api/form.api'
import useConf from '../../hooks/useConf'
import Form from '../../shared/form/forms'

const CompaniesList = ({ toSiren, toCreate, companyId }) => {
  const [selected, setSelected] = useState()
  const [ref, setRef] = useState()

  const myContext = useMyContext()
  const { nextStep, userId: userId2, NextButton, CompanionName, participation } = useSteps()
  const { companiesFormId, companyIndexLabel } = useConf(participation)

  const { domainId, userId, settings } = myContext

  const { data: companies } = useGetCompaniesQuery({ domainId, userId })
  const { data: user } = useGetEventUserQuery({ ...myContext, userId: userId2 })
  const [updateUserEvent] = useUpdateEventUserMutation()
  const [addEmployee] = useAddEmployeeMutation()
  const [removeEmployee] = useRemoveEmployeeMutation()
  const { data: form } = useGetFormQuery({ ...myContext, formId: companiesFormId })

  useEffect(() => {
    if (user && companies?.length > 0) {
      if (companyId) {
        const find = companies.find(f => f.id === companyId)
        if (find) {
          setSelected(companyId)
          return
        }
      }

      const find = companies.find(f => f.id === user?.UserEvent?.Employee?.Company?.id)
      if (find) {
        setSelected(find.id)
      }
    } else {
      setSelected(null)
    }
  }, [companies, companyId, user])

  useEffect(() => {
    if (companies?.length === 1) {
      setSelected(companies[0].id)
    }
  }, [companies])

  return (
    <div>
      {settings?.FO_MEETING_COMPANIES_HTML && (
        <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.FO_MEETING_COMPANIES_HTML.html }} />
      )}
      {CompanionName}
      <Button type="primary" onClick={toSiren}>
        Ajouter une société à ma liste
      </Button>
      <Table
        rowKey="id"
        dataSource={companies}
        size="small"
        onRow={v => {
          return { onClick: () => setSelected(v.id) }
        }}
        scroll={{ x: true }}
        locale={{ emptyText: 'Veuillez ajouter une société à votre liste' }}
        columns={[
          {
            title: 'votre sélection',
            render: company => (
              <Space>
                <Button type="primary" onClick={() => removeEmployee({ ...myContext, companyId: company.id })} size="small">
                  Retirer
                </Button>
              </Space>
            ),
          },
          { title: 'Nom', dataIndex: 'name' },
          { title: companyIndexLabel || 'SIREN', dataIndex: 'siren' },
          { title: 'Adresse', dataIndex: 'address1' },
          { title: 'Code postal', dataIndex: 'postalCode' },
          { title: 'Ville', dataIndex: 'city' },
          { render: v => <Radio checked={v.id === selected} onChange={() => setSelected(v.id)} /> },
          {
            title: '',
            render: company => (
              <Space>
                <Button type="primary" onClick={() => toCreate(company)} size="small">
                  Modifier
                </Button>
              </Space>
            ),
          },
        ]}
      />
      {form && (
        <div style={{ display: 'none' }}>
          <Form company={companies?.find(f => f.id === selected)} form={form} setRef={setRef} />
        </div>
      )}
      <NextButton
        onClick={async () => {
          const company = companies?.find(f => f.id === selected)
          if ((ref && !ref.checkValidity()) || !company.siren) {
            toCreate(company)
          } else {
            const employee = await addEmployee({ ...myContext, userId: userId2, companyId: selected }).unwrap()
            await updateUserEvent({ ...myContext, userId: userId2, eventUser: { employeeId: employee.id } })
            nextStep()
          }
        }}
        disabled={!selected}
      />
    </div>
  )
}

export default CompaniesList
