import React, { useCallback, useEffect, useState } from 'react'
import { useMyContext } from '../context/context'
import useLog from '../hooks/useViewLog'
import FormApi from '../services/api/Form.api'
import Flex from '../shared/Flex'
import Form from '../shared/form/forms'

function SatisfactionForm() {
  const { settings, user } = useMyContext()

  const [form, setForm] = useState(null)
  const [isSaved, setIsSaved] = useState(false)

  const formId = settings?.satisfactionFormId

  const [createLog] = useLog('VIEW_SATISFACTION')

  const fetchForm = useCallback(async () => {
    if (formId) new FormApi().getById(formId).then(setForm)
  }, [formId])

  useEffect(() => {
    fetchForm()
  }, [fetchForm])

  return (
    <>
      {settings?.satisfactionHtml && (
        <div style={{ padding: 20 }} dangerouslySetInnerHTML={{ __html: settings.satisfactionHtml.html || settings.satisfactionHtml }} />
      )}
      {!isSaved && user && form && (
        <Form
          user={user}
          form={form}
          afterSave={() => {
            createLog('SATISFACTION_SUBMIT')
            setIsSaved(true)
          }}
        />
      )}
      {isSaved && (
        <Flex>
          <article className="message is-success field">
            <div className="message-body">Votre réponse a bien été prise en compte</div>
          </article>
        </Flex>
      )}
    </>
  )
}

export default SatisfactionForm
