import React, { FormEvent, useState } from 'react'
import { useMyContext } from '../context/context'
import useHistoryCustom from '../hooks/useHistoryCustom'
import UserApi from '../services/api/User.api'
import ROUTES from '../services/routes'
import Button from '../shared/form/button'
import Input from '../shared/form/input'
import MessageError from '../shared/message.error'

const CreateAccount = () => {
  const history = useHistoryCustom()
  const { settings, login } = useMyContext() as any

  const [firstName, setFirstname] = useState<string>('')
  const [lastName, setLastname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setLoading(true)
    setError('')

    try {
      const { token } = await new UserApi().create({
        firstName,
        lastName,
        email,
        password,
      })
      login(token)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div style={{ padding: '10px' }} className="form">
      <div style={{ textAlign: 'center' }} className="is-size-5 has-text-weight-bold">
        S'inscrire
      </div>
      <form
        onSubmit={onSubmit}
        className="field"
        style={{
          width: '390px',
          maxWidth: '90vw',
        }}
      >
        <Input value={firstName} onChange={setFirstname} type="text" label="Prénom" minLength={2} required isForm />
        <Input value={lastName} onChange={setLastname} type="text" label="Nom" minLength={2} required isForm />
        <Input value={email} onChange={setEmail} type="email" label="Email" required isForm />
        <Input value={password} onChange={setPassword} type="password" label="Mot de passe" required isForm />
        {error === 'Cet email est déjà utilisé sur ce domaine' ? (
          <article className="message is-danger field">
            <div className="message-body">
              {settings?.FO_MEETING_REGISTER_EMAIL_EXISTS_HTML ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: settings?.FO_MEETING_REGISTER_EMAIL_EXISTS_HTML.html,
                  }}
                />
              ) : (
                <div>
                  Cette adresse email est déjà utilisée par un autre utilisateur.
                  <br />
                  Si cette adresse email est la vôtre, nous vous invitons à vous connecter par l’étape :
                </div>
              )}
              <div className="has-text-centered">
                <Button
                  style={{ marginTop: '1.25em' }}
                  label={settings?.FO_MEETING_REGISTER_BUTTON_FORGOT_PASSWORD || "J'ai oublié mon mot de passe"}
                  onClick={() => history.push(ROUTES.PASSWORD_FORGOT)}
                />
              </div>
            </div>
          </article>
        ) : (
          <MessageError message={error} />
        )}

        <Button style={{ width: '100%' }} label="VALIDER" loading={loading} submit />
      </form>
    </div>
  )
}

export default CreateAccount
