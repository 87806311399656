import { getDomainId } from '../../utils/store'
import { fetchAsyncWithSignal } from '../fetch'

class SearchApi {
  url: string

  constructor() {
    const domainId = getDomainId()
    this.url = `/domains/${domainId}/search`
  }

  async get(user: boolean, company: boolean, q: string, signal: any) {
    return fetchAsyncWithSignal('GET', `${this.url}?user=${user}&company=${company}&q=${q}`, signal)
  }
}

export default SearchApi
