import { get } from '../fetch'
import { getDomainId, getEventId } from '../../utils/store'

class LeaderboardApi {
  constructor() {
    this.url = `/domains/${getDomainId()}/events/${getEventId()}/leaderboard`
  }

  async findAll(limit = 10, showTeams = false) {
    return get(`${this.url}?limit=${limit}&showTeams=${showTeams}`)
  }
}

export default LeaderboardApi
