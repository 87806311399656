import { post } from '../fetch'
import { getDomainId, getEventId } from '../../utils/store'

class WordCloudApi {
  constructor() {
    const domainId = getDomainId()
    const eventId = getEventId()
    this.url = `/domains/${domainId}/events/${eventId}/wordclouds`
  }

  createResponse(wordCloudId, response) {
    return post(`${this.url}/${wordCloudId}/response`, { response })
  }
}

export default WordCloudApi
