import React from 'react'
import { useMyContext } from '../context/context'

function MessageColor({ children }) {
  const { settings } = useMyContext()

  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: 22,
        background: settings?.bgPrimaryColor,
        color: settings?.textPrimaryColor,
        padding: '5px 10px',
        marginBottom: 10,
      }}
    >
      {children}
    </div>
  )
}

export default MessageColor
