import qs from 'query-string'
import api from '../../api/api'

export const authenticationApi = api.injectEndpoints({
  endpoints: builder => ({
    register: builder.mutation({
      query: ({ domainId, user, eventId }) => ({
        url: `/domains/${domainId}/users?${qs.stringify({ eventId })}`,
        method: 'POST',
        body: user,
      }),
      transformResponse: response => response?.data?.token,
    }),
    checkUserByEmail: builder.query({
      query: ({ domainId, email }) => ({
        url: `/v2/domains/${domainId}/users/check?email=${email}`,
        method: 'GET',
      }),
      transformResponse: response => response?.data,
    }),
    getMe: builder.query({
      query: ({ domainId }) => ({ url: `/domains/${domainId}/users/me`, method: 'GET' }),
      transformResponse: response => response.data,
      providesTags: ['User'],
    }),
    sendForgotPassword: builder.mutation({
      query: ({ domainId, eventId, email, referer }) => ({
        url: `/domains/${domainId}/events/${eventId}/emails/send`,
        method: 'POST',
        body: { email, actionId: 3, variables: { referer } },
      }),
      transformResponse: response => response?.data?.token,
    }),
  }),
})

export const {
  useLazyCheckUserByEmailQuery,
  useRegisterMutation,
  useGetMeQuery,
  useLazyGetMeQuery,
  useSendForgotPasswordMutation,
} = authenticationApi
