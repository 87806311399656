import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react'
import nextId from 'react-id-generator'

interface IProps {
  value: string | undefined
  onChange: (value: string) => void
  label: string
  required?: boolean
  style?: CSSProperties
  isHorizontal?: boolean
  rows?: number
  disabled?: boolean
}

const TextArea = ({ value, onChange, label, required, isHorizontal, rows, disabled }: IProps) => {
  const [id, setId] = useState<string>()

  useEffect(() => {
    setId(nextId('textarea-'))
  }, [])

  return isHorizontal ? (
    <div className="field is-horizontal">
      <div className="field-label is-small">
        <label className="label" htmlFor={id}>
          {required ? (
            <>
              {label}
              <span className="has-text-danger"> *</span>
            </>
          ) : (
            label
          )}
        </label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <textarea
              id={id}
              className="textarea is-small"
              placeholder={required ? `${label}*` : label}
              value={value}
              rows={2}
              onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) => onChange(target.value)}
              required={required}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="field">
      <label className="label is-small" htmlFor={id}>
        {required ? (
          <>
            {label}
            <span className="has-text-danger"> *</span>
          </>
        ) : (
          label
        )}
      </label>
      <div className="control">
        <textarea
          id={id}
          className="textarea is-small"
          placeholder={required ? `${label}*` : label}
          value={value}
          rows={rows || 2}
          onChange={({ target }: ChangeEvent<HTMLTextAreaElement>) => onChange(target.value)}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
export default TextArea
