import React, { useEffect } from 'react'
import { useMyContext } from '../context/context'
import { getDomainId, getEventId } from '../utils/store'

function PresentationFullScreen({ children }) {
  const { settings } = useMyContext()

  useEffect(() => {
    const doc = document.getElementsByTagName('html')[0]
    doc.style['overflow-y'] = 'hidden'
    return () => {
      doc.style['overflow-y'] = 'scroll'
    }
  }, [])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white',
      }}
    >
      {settings?.pollBackground && (
        <img
          src={`https://gayakoa-files-eu-west-1-public.s3.eu-west-1.amazonaws.com/${settings.pollBackground}`}
          style={{ width: '100%' }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default PresentationFullScreen
