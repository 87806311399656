const ROUTES = {
  EVENTS_INSCRIPTION: '/inscription',
  HOME: '',
  LOGIN: '/login',
  LOGIN_WITH_BADGE: '/login-with-badge',
  LIVE: '/live',
  LIVE_ORGA: '/live/orga',
  LIVE_SPEAKER: '/live/speaker',
  PASSWORD_FORGOT: '/password/forgot',
  PASSWORD_NEW: '/password/new',
  CREATE_ACCOUNT: '/account/create',
  MESSAGE: '/message',
  USERS_PRESENT: '/users/present',
  USERS_REGISTERED: '/users/registered',
  SCAN: '/scan',
  BADGE_DOWNLOAD: '/badge/:badgeId/download',
  PHOTOS: '/photos',
  PHOTOS_VIEWER: '/photos/viewer',
  REPLAY: '/replay',
  SATISFACTION: '/satisfaction',
  LEADERBOARD: '/leaderboard',
  COMPANIONS: '/companions',
  COMPANIONS_ADD: '/companions/add',
}

export default ROUTES
