import qs from 'query-string'
import api from './api'

export const logApi = api.injectEndpoints({
  endpoints: builder => ({
    getStats: builder.query({
      query: ({ domainId, eventId, isRemote }) => `/v2/domains/${domainId}/events/${eventId}/stats?${qs.stringify({ isRemote })}`,
      transformResponse: response => response.data,
      providesTags: ['EVENT_USER'],
    }),
  }),
})

export const { useGetStatsQuery } = logApi
