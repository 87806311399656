import qs from 'query-string'
import api from './api'

export const companyApi = api.injectEndpoints({
  endpoints: builder => ({
    getCompanies: builder.query({
      query: ({ domainId, userId, siren }) => `/v2/domains/${domainId}/companies?${qs.stringify({ userId, siren })}`,
      transformResponse: response => response.data,
      providesTags: ['Company'],
    }),
    createCompany: builder.mutation({
      query: ({ domainId, body }) => ({ url: `/v2/domains/${domainId}/companies`, method: 'POST', body }),
      transformResponse: response => response.data,
      invalidatesTags: ['Company'],
    }),
    updateCompany: builder.mutation({
      query: ({ domainId, companyId, body }) => ({ url: `/v2/domains/${domainId}/companies/${companyId}`, method: 'PUT', body }),
      transformResponse: response => response.data,
      invalidatesTags: ['Company'],
    }),
    createEmployee: builder.mutation({
      query: ({ domainId, body }) => ({ url: `/v2/domains/${domainId}/employees`, method: 'POST', body }),
      transformResponse: response => response.data,
      invalidatesTags: ['Company'],
    }),
    addEmployee: builder.mutation({
      query: ({ domainId, companyId, userId }) => ({ url: `/domains/${domainId}/companies/${companyId}/users/${userId}`, method: 'POST' }),
      transformResponse: response => response.data,
      invalidatesTags: ['Company'],
    }),
    removeEmployee: builder.mutation({
      query: ({ domainId, companyId, userId }) => ({
        url: `/domains/${domainId}/companies/${companyId}/users/${userId}/archive`,
        method: 'PUT',
      }),
      transformResponse: response => response.data,
      invalidatesTags: ['Company'],
    }),
  }),
})

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useCreateEmployeeMutation,
  useAddEmployeeMutation,
  useRemoveEmployeeMutation,
} = companyApi
