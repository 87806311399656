import React from 'react'
import { useMyContext } from '../../../context/context'

function cutArray(tableau, tailleLot) {
  const lots = []

  for (let i = 0; i < tableau.length; i += tailleLot) {
    const lot = tableau.slice(i, i + tailleLot)
    lots.push(lot)
  }

  return lots
}

function Bars({ responses, totalVotes, showRightAnswers, eventLive }) {
  const { settings } = useMyContext()

  return (
    <div style={{ display: 'flex', gap: 16 }}>
      {cutArray(responses, 6).map(a => (
        <div>
          {a.map(c => {
            const percentage = c.votes && totalVotes ? (c.votes * 100) / totalVotes : 0

            return (
              <div key={Math.random()}>
                <div style={{ color: 'black', display: 'flex', alignItems: 'center', fontSize: '1.2em' }}>
                  {c.image && <img style={{ width: 30, height: 30, marginRight: 10 }} src={c.image} />}
                  <div>
                    <strong>{c.label}</strong> ({!eventLive.hideVotes && `${c.votes} réponse${c.votes > 1 ? 's' : ''} soit`}{' '}
                    {Math.floor(percentage)}%)
                  </div>
                </div>
                <div
                  style={{
                    animation: !showRightAnswers ? 'width0to100 4s ease' : '',
                    background: c.isRightAnswer && showRightAnswers ? c.color : settings?.bgPrimaryColor,
                    marginBottom: 20,
                    width: `${percentage}%`,
                    height: 30,
                    minWidth: 1,
                  }}
                />
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default Bars
