import { useGetTyposQuery } from '../api/v2/typo'
import { useMyContext } from '../context/context'

const useTypos = () => {
  const { settings, myContext } = useMyContext()

  const { data: typos, isLoading } = useGetTyposQuery(myContext)

  return {
    data:
      typos
        ?.filter(f => (f.id ? !settings?.typosV2?.[f.id]?.HIDE : !settings?.FO_MEETING_TYPO_DEFAULT_HIDE))
        .map(m => ({ ...m, label: settings?.typosV2?.[m.id]?.LABEL || m.label })) || [],
    isLoading,
  }
}

export default useTypos
